import axiosClient from '../utils/axios';
import {
  CROP_TYPES_FETCHED,
  LANGUAGES_FETCHED,
  METRIC_SYSTEMS_FETCHED,
  PIXEL_MAP_TYPES_FETCHED,
  IRRIGATION_TYPES_FETCHED,
  OPERATION_TYPES_FETCHED,
  SOIL_TYPES_FETCHED,
  USER_CATEGORIES_FETCHED,
  COUNTRIES_FETCHED,
  CULTIVATION_METHODS_FETCHED,
  WORKSPACES_FETCHED,
  GENDERS_FETCHED,
} from "../actionTypes/actionTypes";

export const fetchCropTypes = () => async (dispatch) => {
  await axiosClient
    .get('/ct/')
    .then(({ data }) => {
      dispatch({
        type: CROP_TYPES_FETCHED,
        payload: data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};
export const fetchMetricSystems = () => async (dispatch) => {
  await axiosClient
    .get('/ms/')
    .then(({ data }) => {
      dispatch({
        type: METRIC_SYSTEMS_FETCHED,
        payload: data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};
export const fetchGenders = () => async (dispatch) => {
  await axiosClient
    .get('/auth/users/gender/')
    .then(({ data }) => {
      dispatch({
        type: GENDERS_FETCHED,
        payload: data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};
export const fetchIrrigationTypes = () => async (dispatch) => {
  await axiosClient
    .get('/se/irrigationtype/')
    .then(({ data }) => {
      dispatch({
        type: IRRIGATION_TYPES_FETCHED,
        payload: data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};
export const fetchCultivationMethods = () => async (dispatch) => {
  await axiosClient
    .get('/se/cultivationmethod/')
    .then(({ data }) => {
      dispatch({
        type: CULTIVATION_METHODS_FETCHED,
        payload: data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};
export const fetchWorkSpaces = () => async (dispatch) => {
  await axiosClient
    .get('/ws/')
    .then(({ data }) => {
      dispatch({
        type: WORKSPACES_FETCHED,
        payload: data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};
export const fetchOperationTypes = () => async (dispatch) => {
  await axiosClient
    .get('/operations/operationtype/')
    .then(({ data }) => {
      dispatch({
        type: OPERATION_TYPES_FETCHED,
        payload: data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};
export const fetchPixelMapTypes = () => async (dispatch) => {
  await axiosClient
    .get('/pmt/')
    .then(({ data }) => {
      dispatch({
        type: PIXEL_MAP_TYPES_FETCHED,
        payload: data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};
export const fetchUserCategories = () => async (dispatch) => {
  await axiosClient
    .get('/uc/')
    .then(({ data }) => {
      dispatch({
        type: USER_CATEGORIES_FETCHED,
        payload: data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};
export const fetchLanguages = () => async (dispatch) => {
  await axiosClient
    .get('/la/')
    .then(({ data }) => {
      dispatch({
        type: LANGUAGES_FETCHED,
        payload: data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};
export const fetchSoilTypes = () => async (dispatch) => {
  await axiosClient
    .get('/st/')
    .then(({ data }) => {
      dispatch({
        type: SOIL_TYPES_FETCHED,
        payload: data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};
export const fetchCountries = () => async (dispatch) => {
  await axiosClient
    .get('/countries/')
    .then(({ data }) => {
      dispatch({
        type: COUNTRIES_FETCHED,
        payload: data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};

