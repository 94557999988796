import React from  'react';
import { useDispatch, useSelector } from 'react-redux';
import { createGrowingSeasonDS } from '../dataServices/growingSeasons';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import toastr from 'toastr';
import { useTranslation } from 'react-i18next';

import ContentHeader from './../layouts/components/ContentHeader';
import GrowingSeasonForm from './../components/forms/GrowingSeasonForm';

const FarmLandCreateView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const farmlandId = searchParams.get('farmlandId');

  const onSubmit = async (values) => {
    return dispatch(createGrowingSeasonDS(values))
      .then(data => {
        if (data.success && data?.growingSeason?.season_id) {
          navigate(`/growing-seasons/${data?.growingSeason?.season_id}`);
          return;
        } else {
          if (data?.detail) {
            toastr.error(data?.detail);
          }
        }
        return data;
      })
      .catch(err => {
        console.log('ERROR #', err);
      });
  }

  return (
    <>
      <ContentHeader title={t('New Growing Season')} />

      <section className="page">
        <GrowingSeasonForm
          initialValues={{
            farmland: farmlandId,
          }}
          onSubmit={onSubmit}
        />
      </section>
    </>
  )
};

export default FarmLandCreateView;
