import React, { memo } from 'react'
import ReactWeather, { useOpenWeather } from 'react-open-weather';
import { OPEN_WEATHER_MAP_API_KEY } from '../constants/const';

const customStyles = {
  fontFamily:  'Roboto',
  gradientStart:  '#fff',
  gradientMid:  '#fff',
  gradientEnd:  '#fff',
  locationFontColor:  '#999',
  todayTempFontColor:  '#555',
  todayDateFontColor:  '#444',
  todayRangeFontColor:  '#444',
  todayDescFontColor:  '#444',
  todayInfoFontColor:  '#444',
  todayIconColor:  '#1B986B',
  forecastBackgroundColor:  '#FFF',
  forecastSeparatorColor:  '#DDD',
  forecastDateColor:  '#777',
  forecastDescColor:  '#777',
  forecastRangeColor:  '#777',
  forecastIconColor:  '#1B986B',
};

const WeatherWidget = ({ coordinates, locationLabel }) => {
  const { data, isLoading, errorMessage } = useOpenWeather({
    key: OPEN_WEATHER_MAP_API_KEY,
    lat: coordinates.latitude,
    lon: coordinates.longitude,
    lang: 'en',
    unit: 'metric', // values are (metric, standard, imperial)
  });

  return (
    <div className="weather-widget">
      <ReactWeather
        theme={customStyles}
        isLoading={isLoading}
        errorMessage={errorMessage}
        data={data}
        lang="en"
        locationLabel={locationLabel || ''}
        unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
        showForecast
      />
    </div>
  );
};

export default memo(WeatherWidget)
