import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';


const SensorDataChart = ({ sensorData }) => {
  const scales = {
    x: {
      type: 'time',
      time: {
        unit: "day",
        unitStepSize: 1000,
        displayFormats: {
          millisecond: 'MMM DD',
          second: 'MMM DD',
          minute: 'MMM DD',
          hour: 'MMM DD',
          day: 'MMM DD',
          week: 'MMM DD',
          month: 'MMM DD',
          quarter: 'MMM DD',
          year: 'MMM DD',
        }
      },
      title: {
        display: false,
        text: 'Date'
      },
    },
    y: {
      title: {
        display: true,
        text: 'Soil moisture m3/m3'
      },
    },
  };

  const options = {
    responsive: true,
    scales,
  };

  const labels = sensorData.map(sd => sd['Date_Time']);

  const data = {
    labels,
    datasets: [
      {
        label: '5cm',
        data: sensorData.map(sd => sd['A1_5']),
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        xAxisID: 'x',
      },
      // {
      //   label: 'T1_5',
      //   data: sensorData.map(sd => sd['T1_5']),
      //   pointBackgroundColor: 'transparent',
      //   pointBorderColor: 'transparent',
      // },
      {
        label: '15cm',
        data: sensorData.map(sd => sd['A2_15']),
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        hidden: true,
        xAxisID: 'x',
      },
      // {
      //   label: 'T2_15',
      //   data: sensorData.map(sd => sd['T2_15']),
      //   pointBackgroundColor: 'transparent',
      //   pointBorderColor: 'transparent',
      //   hidden: true,
      // },
      {
        label: '25cm',
        data: sensorData.map(sd => sd['A3_25']),
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        hidden: true,
        xAxisID: 'x',
      },
      // {
      //   label: 'T3_25',
      //   data: sensorData.map(sd => sd['T3_25']),
      //   pointBackgroundColor: 'transparent',
      //   pointBorderColor: 'transparent',
      //   hidden: true,
      // },
      {
        label: '35cm',
        data: sensorData.map(sd => sd['A4_35']),
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        hidden: true,
        xAxisID: 'x',
      },
      // {
      //   label: 'T4_35',
      //   data: sensorData.map(sd => sd['T4_35']),
      //   pointBackgroundColor: 'transparent',
      //   pointBorderColor: 'transparent',
      //   hidden: true,
      // },
      {
        label: '45cm',
        data: sensorData.map(sd => sd['A5_45']),
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        hidden: true,
        xAxisID: 'x',
      },
      // {
      //   label: 'T5_45',
      //   data: sensorData.map(sd => sd['T5_45']),
      //   pointBackgroundColor: 'transparent',
      //   pointBorderColor: 'transparent',
      //   hidden: true,
      // },
      {
        label: '55cm',
        data: sensorData.map(sd => sd['A6_55']),
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        hidden: true,
        xAxisID: 'x',
      },
      // {
      //   label: 'T6_55',
      //   data: sensorData.map(sd => sd['T6_55']),
      //   pointBackgroundColor: 'transparent',
      //   pointBorderColor: 'transparent',
      //   hidden: true,
      // },
    ],
  };
  return (
    <>
      <div className="d-nodne d-lg-block">
        <Line options={options} data={data} />
      </div>
    </>
  );
};

export default SensorDataChart;
