import React, { useEffect, useState } from  'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import qs from 'query-string';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import Calendar from 'react-calendar';
import axiosClient from '../utils/axios';
import { format } from 'date-fns'
import {
  CheckCircleIcon,
  XCircleIcon,
  PlusCircleIcon
} from '@heroicons/react/24/solid'

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { useTranslation } from 'react-i18next';
import toastr from 'toastr';
import { Modal, Button } from 'react-bootstrap';

import { updateOperationDS, createOperationDS } from '../dataServices/operations';
import OperationTransactionForm from './../components/forms/OperationTransactionForm';
import { deleteOperationDS } from '../dataServices/operations';
import { formatDate } from '../utils/utils';
import { fetchOperationsDS } from '../dataServices/operations';

const OperationsTimeLine = ({ growingSeasonId, modalOpen, onClose, onCreateNew }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  let [searchParams, setSearchParams] = useSearchParams();
  const pagination = useSelector((state) => state?.app?.pagination);
  const { search } = useLocation();
  const currentPage = qs.parse(search).page || 1;
  const searchAsObject = Object.fromEntries(
    new URLSearchParams(searchParams)
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [operations, setOperations] = useState([]);
  const [operationAttributes, setOperationAttributes] = useState([]);
  const [operation, setOperation] = useState(null);
  const [showOperationModal, setShowOperationModal] = useState(false);
  const [allOperations, setAllOperations] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedType, setSelectedType] = useState('operations');

  useEffect(() => {
    fetchAllItems();
  }, [search, modalOpen]);

  const fetchAllItems = async () => {
    const filters = { season_id: growingSeasonId };
    // dispatch(fetchOperationsDS(currentPage, 1000, filters)).then(() => {
    //   setIsLoading(false);
    // });
    await axiosClient.get(
      `/operations/?season_id=${growingSeasonId}&pageSize=1000`
      //`fl/${farmlandId}/calc/weather_forecast/`
    )
      .then(
        ({ data: { results } }) => {
          setOperations(results.reverse());
          setIsLoading(false);
        }
      );

    await axiosClient.get(
      `/operations/operationattribute/`
    )
      .then(
        ({ data }) => {
          setOperationAttributes(data);
          setIsLoading(false);
        }
      );
  };

  const extractOpAttrName = (id) => {
    return operationAttributes.find(attr => attr.operationattribute_id === id)?.name
  };

  return (
    <Modal
      show={modalOpen}
      size="xl"
      className="timeline"
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-center modal-title h4 w-100">{t('Timeline')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && 'Loading...'}

        {!isLoading && (
          <div className="row">
            <div className="col-12">
              {!isLoading && operations && operations.length === 0 && (
                <div className="text-center p-5">
                  <i>{t('no operations recorded yet')}</i>
                </div>
              )}

              {operations?.length > 0 && (
                <VerticalTimeline>
                  {operations.map(op => {
                    return (
                      <VerticalTimelineElement
                        key={op.operation_id}
                        className="vertical-timeline-element--work"
                        // contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  #1B986B' }}
                        date={formatDate(op.operationdate)}
                        iconStyle={{ background: '#1B986B', color: '#fff' }}
                        icon={<CheckCircleIcon />}
                      >
                        <h3 className="vertical-timeline-element-title  mb-2">{op.title}</h3>
                        {op?.operationdetails?.map((od, i) => {
                          return (
                            <div key={i} className="mt-2 text-small">
                              <small className="mt-2 text-muted">{extractOpAttrName(od.operationattribute)}</small>
                              <div>{od.attribute_value?.value}</div>
                            </div>
                          )
                        })}

                        {op.description && (
                          <div className="mt-2 d-block">
                            <small className="text-muted">{t('Comments')}</small>
                            <p className="m-0">
                              {op.description}
                            </p>
                          </div>
                        )}

                        {
                          op.operationfiles.length > 0 && (
                          <div className="row my-6 pt-4">
                            {op.operationfiles.map(({ fileresource: f }, key) => (
                              <div
                                className="col-12 col-lg-6 attachment-item mb-4"
                                key={key}
                              >
                                {f?.mimetype?.includes('image') && (
                                  <a href={f.file} target="_blank">
                                    <img
                                      style={{
                                        width: '100%',
                                        height: 'auto',
                                      }}
                                      src={f.file}
                                    />
                                  </a>
                                )}

                                { !f?.mimetype?.includes('image') && f?.file }
                              </div>
                            ))}
                          </div>
                        )}
                      </VerticalTimelineElement>
                    );
                  })}
                  <VerticalTimelineElement
                    className="add-new"
                    iconStyle={{ background: '#1B986B', color: '#fff' }}
                    icon={<PlusCircleIcon onClick={onCreateNew} className="cursor-pointer" />}
                  />
                </VerticalTimeline>
              )}
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
};

export default OperationsTimeLine;
