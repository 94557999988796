const InputField = ({
  label,
  type,
  step,
  max,
  cta,
  min,
  size,
  disabled,
  prefix = null,
  suffix = null,
  wrapperClass = 'mb-3',
  input: {
    name,
    ...rest
  },
  meta: {
    touched,
    error,
    submitError,
  },
  meta,
  placeholder,
}) => (
  <div className={wrapperClass}>
    {label && (
        <label
        htmlFor={name}
        className="form-label"
      >
        {label}
      </label>
    )}

    {(suffix || prefix) && (
      <>
        <div className={disabled ? 'input-group mb-3 disabled' : 'input-group mb-3'}>
          {prefix && (
            <span className="input-group-text">{prefix}</span>
          )}
          <input
            disabled={disabled}
            type={type}
            min={min}
            max={max}
            step={step}
            className={`form-control ${size ? `form-control-${size}` : '' }`}
            placeholder={placeholder}
            {...rest}
          />

          {suffix && (
            <span className="input-group-text">{suffix}</span>
          )}
        </div>
      </>
    )}

    {(!suffix && !prefix) && (
      <input
        disabled={disabled}
        type={type}
        step={step}
        min={min}
        max={max}
        className={`form-control ${size ? `form-control-${size}` : '' }`}
        placeholder={placeholder}
        {...rest}
      />
    )}
    {cta}

    {(error || submitError) && touched && (
      <div className="text-danger my-1">{error || submitError}</div>
    )}
  </div>
);

export default InputField;
