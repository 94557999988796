import React, { useEffect, useState } from  'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ContentHeader from './../layouts/components/ContentHeader';
import { fetchFarmLandsDS, deleteFarmLandDS } from '../dataServices/farmLands';
import FarmLandListItem from './FarmLandListItem';
import Pagination from './../components/Pagination';
import SearchForm from '../components/forms/SearchForm';

const FarmLandsView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalPayload, setDeleteModalPayload] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const farmLands = useSelector((state) => state?.app?.farmLands);
  const pagination = useSelector((state) => state?.app?.pagination);
  const { search } = useLocation();

  const currentPage = qs.parse(search).page || 1;
  const q = qs.parse(search)?.q;
  const searchAsObject = Object.fromEntries(
    new URLSearchParams(searchParams)
  );

  useEffect(() => {
    dispatch(fetchFarmLandsDS(currentPage, 9, { q })).then(() => {
      setIsLoading(false);
      document.querySelector('.page').scrollTo({top: 0, behavior: 'smooth'});
    });
  }, [search]);

  const handleFarmlandDelete = async () => {
    await dispatch(deleteFarmLandDS(deleteModalPayload?.farmland_id));
    setShowDeleteModal(false);
    setDeleteModalPayload(null);
    setIsLoading(true);
    dispatch(fetchFarmLandsDS()).then(() => {
      setIsLoading(false);
    });
  }

  return (
    <>
      <ContentHeader
        title={t('Manage farmlands')}
        cta={(
          <Link
            className="btn btn-primary ms-auto"
            to="/farm-lands/add"
          >
            {t('Add Farmland')}
          </Link>
        )}
      />

      <section className="page">
        <div className="container">
          {isLoading && 'Loading...'}

          <div>
            <SearchForm
              initialValues={{
                q: searchAsObject?.q,
              }}
              onSubmit={(values => {
                setSearchParams({
                  ...searchAsObject,
                  ...values,
                  q: values.q || '',
                  page: 1,
                });
              })}
            />

            {!isLoading && farmLands && farmLands.length === 0 && (
              <div className="text-center p-5">
                {searchAsObject?.q
                  ? <i>No farmlands found</i>
                  : (
                    <>
                      <i>You haven't added any farmlands yet.</i>
                      <br />
                      <Link to="/farm-lands/add">
                      Click here to add one
                      </Link>
                    </>
                  )
                }
              </div>
            )}

            <div className="items-list col-12">
              <ul className="row p-0">
                {farmLands.map((fl) => (
                  <FarmLandListItem
                    key={fl.farmland_id}
                    farmland={fl}
                    setDeleteModalPayload={setDeleteModalPayload}
                    setShowDeleteModal={setShowDeleteModal}
                  />
                ))}

                <div className="my-4">
                  {/*<div className="text-muted">Total farmlands: {pagination.count}</div>*/}

                  <div className="w-100">
                    {pagination && (
                      <Pagination
                        {...pagination}
                        changePage={newPage => {
                          setSearchParams({
                            ...searchAsObject,
                            page: newPage,
                          });
                        }}
                      />
                    )}
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>

        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Farmland</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {'Are you sure you want to delete '}
            <strong>{deleteModalPayload?.name}</strong>
            ?
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
              Close
            </Button>

            <Button
              variant="danger"
              onClick={handleFarmlandDelete}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </>
  )
};

export default FarmLandsView;
