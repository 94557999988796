import React, { useEffect, useState, useCallback } from  'react';
import { useSelector } from 'react-redux';
import { Chart as ChartJS, Colors, ArcElement, Tooltip, Legend } from "chart.js";
import { useTranslation } from 'react-i18next';

import { Doughnut } from "react-chartjs-2";

import { formatDate } from '../utils/utils';
import axiosClient from '../utils/axios';
import ContentHeader from './../layouts/components/ContentHeader';
import { Card } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import logoCircle from './../img/logo-circle.png';

ChartJS.register(ArcElement, Tooltip, Legend, Colors);


const DashboardView = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [activeWorkSpace, setActiveWorkSpace] = useState(null);
  const [cropAreaStats, setCropAreaStats] = useState([]);
  const [cropTypeBalance, setCropTypeBalance] = useState(null);
  const [operations, setOperations] = useState([]);
  const [aggregatedCroptypeData, setAggregatedCroptypeData] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const navigate = useNavigate();
  const userData = useSelector((state) => state?.auth?.user);
  const countries = useSelector((state) => state?.globals?.countries);
  const workSpaces = useSelector((state) => state?.globals?.workSpaces);
  const userCountry = countries?.find(c => c.country_id === activeWorkSpace?.creator?.country ) || '';
  console.log({ userData, workSpaces });

  useEffect(() => {
    fetchStats();
  }, []);

  useEffect(() => {
    if (!userData || !workSpaces) {
      return;
    }

    if (userData && workSpaces?.length > 0) {
      const activeWS = workSpaces.find(ws => ws.workspace_id === userData?.active_ws);
      if (activeWS) {
        console.log({ activeWS });
        setActiveWorkSpace(activeWS);
      }
    }
  }, [userData, workSpaces]);



  const fetchStats = async () => {
    await axiosClient
      .get('se/croptype-area/')
      .then(({ data }) => {
        const mappedData = data.map(i => [i.name, i.total_area / 1000 ] );
        setCropAreaStats(mappedData);
      })
      .catch(function (err) {
        return 'error';
      });

    await axiosClient
      .get(`/operations/?page_size=10`)
      .then(({ data }) => {
        setOperations(data.results);
      })
      .catch(function (err) {
        return 'error';
      });

    await axiosClient
      .get(`/transactions/?limit=5`)
      .then(({ data }) => {
        setTransactions(data.results);
      })
      .catch(function (err) {
        return 'error';
      });
    await axiosClient
      .get(`/se/croptype-balance/`)
      .then(({ data }) => {
        setCropTypeBalance(data);
      })
      .catch(function (err) {
        return 'error';
      });
    setIsLoading(false);
    await axiosClient
      .get(`/se/aggregated-croptype-data/ `)
      .then(({ data }) => {
        setAggregatedCroptypeData(data);
      })
      .catch(function (err) {
        return 'error';
      });
    setIsLoading(false);
  }
  const dataset = {
    labels: cropAreaStats?.map(ca => ca[0]),
    datasets: [
      {
        label: 'Total area (ha)',
        data: cropAreaStats?.map(ca => ca[1]*1000),
        backgroundColor: [
          'rgba(36, 118, 108, 1)',
          'rgba(163, 43, 84,  1)',
          'rgba(15, 81, 77,  1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64,1)',
        ],
        borderColor: [
          'rgba(26, 165, 148, 1)',
          'rgba(163, 43, 84, 1)',
          'rgba(15, 81, 77, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };


  return (
    <>
      <ContentHeader title={t('Dashboard')} />

      <section className="page">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 mb-4">
              { activeWorkSpace && (
                <Card style={{ backgroundColor: '#e1f6f4', border: '5px solid #23766c' }}>
                  <Card.Body className="p-4">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <h2 className="mt-2 mb-4">
                          {/*{activeWorkSpace?.creator?.first_name && activeWorkSpace?.creator?.first_name !== '' && (*/}
                          {/*  <span>*/}
                          {/*    {activeWorkSpace?.creator?.first_name}*/}
                          {/*  </span>*/}
                          {/*)}*/}
                        </h2>

                        {activeWorkSpace?.creator?.avatar && (
                          <div className="mt-3 mb-4">
                            <img src={activeWorkSpace?.creator?.avatar} width={80}/>
                          </div>
                        )}
                        {!activeWorkSpace?.creator?.avatar && (
                          <div className="mt-3 mb-4 logo-muted">
                            <img src={logoCircle} width={80}/>
                          </div>
                        )}

                        {activeWorkSpace?.creator?.first_name === '' && (
                          <span>
                              {activeWorkSpace?.creator?.email}
                            </span>
                        )}
                        {activeWorkSpace?.creator?.first_name !== '' && `${t('Full name')}: ${activeWorkSpace?.creator?.first_name || ''} ${activeWorkSpace?.creator?.last_name || ''}`} <br />
                        {`${t('Country')}: ${userCountry?.name || ''}`} <br />
                      </div>
                      <div className="col-12 col-md-6">
                        { cropAreaStats && (
                          <Doughnut
                            data={dataset}
                            // data={[
                            //   ["Task", "Hours per Day"],
                            //   ...cropAreaStats,
                            // ]}
                          />
                        )}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              )}
            </div>

            <div className="col-12 col-md-6 mb-4">
              {aggregatedCroptypeData && (
                <Card style={{ height: '100%' }}>
                  <Card.Header><h3>{t('Stats by Crop Type')}</h3></Card.Header>
                  <Card.Body >
                    <table className="table table-hover table--stats-by-crop-type">
                      <thead>
                        <tr>
                          <th scope="col">{t('Crop')}</th>
                          <th scope="col">{t('Total Area')}</th>
                          <th scope="col">{t('Nr. of fields')}</th>
                          <th scope="col">{t('Operations')}</th>
                          <th scope="col">{t('Total fuel Consumption')}</th>
                          <th scope="col">{t('Total Irrigation')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {aggregatedCroptypeData?.map(({ croptype_name, num_of_cultivations, num_of_operations, total_area, total_consumption, total_irrigation })=> {
                          return (
                            <tr key={croptype_name}>
                              <td>{croptype_name}</td>
                              <td>{total_area} ha</td>
                              <td>{num_of_cultivations} </td>
                              <td>{num_of_operations}</td>
                              <td>{total_consumption} {t('Litre')}</td>
                              <td>{total_irrigation} {total_irrigation && (<span>m<sup>3</sup></span>) || '-'}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </Card.Body>
                </Card>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12  mb-4">
              <Card>
                <Card.Header><h3>{t('Latest operations')}</h3></Card.Header>
                <Card.Body  style={{ minHeight: '260px' }}>
                  {operations?.length === 0 && (
                    <i>No data available...</i>
                  )}

                  {operations?.length > 0 && (
                    <table className="table table--clickable">
                      <thead>
                        <tr>
                          <th style={{ width: '120px' }} scope="col">
                            {t('Date')}
                          </th>
                          <th scope="col">{t('Operation')}</th>
                          <th scope="col">{t('Growing season')}</th>
                        </tr>
                      </thead>

                      <tbody>
                      {operations?.length > 0 && operations.map(op => (
                        <tr key={op.operation_id} onClick={() => navigate(`/operations/${op.operation_id}/edit`)}>
                          <td style={{ width: '120px' }} className="discreet">
                            {formatDate(op.operationdate)}
                          </td>
                          <td>{op.title}</td>

                          <td>
                            {/*<span className={op.isexpense ? 'text-danger' : 'text-success'}>{op.amount}&euro;</span>*/}
                            <span>{op.season_name}</span>
                          </td>

                        </tr>
                      ))}
                      </tbody>
                    </table>
                  )}
                </Card.Body>


                <Card.Footer className="d-flex">
                  <div className="ms-auto">
                    <Link to="/operations">{t('View all')}</Link>
                  </div>
                </Card.Footer>
              </Card>
            </div>

            <div className="col-12 col-md-6 mb-4 mb-4">

            </div>




            {/*<div className="col-12 col-md-6 mb-4">*/}
            {/*  <Card>*/}
            {/*    <Card.Header><h3></h3></Card.Header>*/}
            {/*    <Card.Body>*/}
            {/*      ,*/}
            {/*    </Card.Body>*/}
            {/*  </Card>*/}
            {/*</div>*/}
          </div>
        </div>
      </section>
    </>
  )
};

export default DashboardView;
