import React, { memo, useState, useEffect } from 'react'
import { GoogleMap, InfoWindow, Marker, LoadScript, DrawingManager, Polygon } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import sensorIcon from '../img/moisture-icon.png';

import { useSelector } from 'react-redux';

const containerStyle = {
  width: '100%',
  height: '450px',
  maxHeight: '70vh',
};

const defaultCenter = {
  lat: 22.0996,
  lng: 39.3608,
};

const FLMap = ({ sensorData = null, farmLand, overlay, pixelMapData, pMFilters, colorMap, sensors = [] }) => {
  const { t } = useTranslation();
  const [drawingMode, setDrawingMode] = useState(null);
  const [overLayPolygon, setOverLayPolygon] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [center, setCenter] = useState(defaultCenter);
  const [map, setMap] = useState(null);
  const [initialPolygonPaths, setInitialPolygonPaths] = useState(null);
  const [polygon, setPolygon] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);


  let pixelMapTypes = [];
  useSelector((state) => state?.globals?.pixelMapTypes)
    .forEach(pmt => {
      pixelMapTypes[pmt.pmtype_id] = pmt;
    });

  useEffect(() => {
    if (farmLand && map) {
      const coords = farmLand?.coordinates?.[0].map(point => ({ lat: point[0], lng: point[1]}));
      const bound = new window.google.maps.LatLngBounds();

      for (let i = 0; i < coords.length; i++) {
        bound.extend( new window.google.maps.LatLng(coords[i]['lat'], coords[i]['lng']) );
      }

      setCenter({
        lat: bound.getCenter().lat(),
        lng: bound.getCenter().lng(),
      });
      setInitialPolygonPaths(coords);
      map.fitBounds(bound);
    }
  }, [farmLand, map]);

  useEffect(() => {
    if (!map) {
      return;
    }
    const google = window.google;

    let overlay;

    USGSOverlay.prototype = new google.maps.OverlayView();


    const coords = farmLand?.coordinates?.[0].map(point => ({ lat: point[0], lng: point[1]}));
    const bounds = new window.google.maps.LatLngBounds();

    for (let i = 0; i < coords.length; i++) {
      bounds.extend( new window.google.maps.LatLng(coords[i]['lat'], coords[i]['lng']) );
    }

    setInitialPolygonPaths(coords);
    map.fitBounds(bounds);

    const rectangle = new google.maps.Rectangle({
      strokeColor: 'red',
      strokeOpacity: 0,
      strokeWeight: 0,
      fillColor: 'green',
      fillOpacity: 0,
      map,
      bounds, // calcBounds(map.getCenter(), new google.maps.Size(2.7, 20))
    });


    if (!window?.google?.maps) {
      return ' -';
    }
    var rectPoly = createPolygonFromRectangle(rectangle); //create a polygom from a rectangle


    // function calcBounds(center, size) {
    //   var n = google.maps.geometry.spherical.computeOffset(center, size.height / 2, 0).lat(),
    //     s = google.maps.geometry.spherical.computeOffset(center, size.height / 2, 180).lat(),
    //     e = google.maps.geometry.spherical.computeOffset(center, size.width / 2, 90).lng(),
    //     w = google.maps.geometry.spherical.computeOffset(center, size.width / 2, 270).lng();
    //   return new google.maps.LatLngBounds(new google.maps.LatLng(s, w),
    //     new google.maps.LatLng(n, e))
    // }



    // var srcImage = 'https://developers.google.com/maps/documentation/' +
    //   'javascript/examples/full/images/talkeetna.png';

     // const srcImage = '/mousouta-33.png';
    const srcImage = pixelMapData?.Pixelmap && pixelMapData.Pixelmap.replace('http:', 'https:');
    // 'https://sap.telecom.tuc.gr/media/33/2022/12/1/pm1/33.png';

    if (!srcImage) {
      overLayPolygon && overLayPolygon.setMap(null);
      return;
    }
    // const srcImage = '/image_2022_12_12T09_51_57_348Z.png';
    overLayPolygon && overLayPolygon.setMap(null);
    setOverLayPolygon(new USGSOverlay(rectangle.bounds, srcImage, map, rectPoly))

    /** @constructor */
    function USGSOverlay(bounds, image, map, rectPoly) {

      // Now initialize all properties.
      this.bounds_ = bounds;
      this.image_ = image;
      this.map_ = map;
      this.rectPoly_ = rectPoly;

      // Define a property to hold the image's canvas. We'll
      // actually create this canvas upon receipt of the onAdd()
      // method so we'll leave it null for now.
      this.cnv_ = null;

      // Explicitly call setMap on this overlay
      this.setMap(map);
    }

    /**
     * onAdd is called when the map's panes are ready and the overlay has been
     * added to the map.
     */
    USGSOverlay.prototype.onAdd = function() {
      const cnv = document.createElement('canvas');
      this.cnv_ = cnv;
    };

    USGSOverlay.prototype.draw = function() {
      const cnv = this.cnv_;
      cnv.setAttribute('id', 'pm-canvas');
      // cnv.setAttribute('width', 500);
      //cnv.style.width = '508';
      // cnv.style.height = '100%';
      cnv.style.borderWidth = '0px';
      cnv.style.position = 'absolute';
      // cnv.style.opacity = '0.9';
      var ctx = cnv.getContext("2d");
      // ctx.fillStyle = "blue";
      // ctx.fillRect(0, 0, cnv.width, cnv.height);
      rectPoly = this.rectPoly_;
      // console.log(USGSOverlay.getProjection());

      // Create the img element and attach it to the cnv.
      const img = document.createElement('img');
      img.setAttribute('crossorigin', '');
      img.src = this.image_;
      img.addEventListener("load", (e) => {
        ctx.drawImage(img, 0, 0, ctx.canvas.width, ctx.canvas.height);
        img.style.display = "none";
      });

      this.cnv_ = cnv;

      // Add the element to the "overlayImage" pane.
      var panes = this.getPanes();
      panes.overlayImage.appendChild(this.cnv_);


      // We use the south-west and north-east
      // coordinates of the overlay to peg it to the correct position and size.
      // To do this, we need to retrieve the projection from the overlay.
      var overlayProjection = this.getProjection();



      // Retrieve the south-west and north-east coordinates of this overlay
      // in LatLngs and convert them to pixel coordinates.
      // We'll use these coordinates to resize the cnv_.
      var sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest());
      var ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast());
      // Resize the image's cnv_ to fit the indicated dimensions.
      var cnv_ = this.cnv_;
      cnv_.style.left = sw.x + 'px';
      cnv_.style.top = ne.y + 'px';
      cnv_.setAttribute('width', (ne.x - sw.x));
      cnv_.setAttribute('height', (sw.y - ne.y) );
      // cnv_.style.height = (sw.y - ne.y) + 'px';


      cnv.onmousemove = ((e) => {
        ctx.clearRect(0, 0, 1000, 1000);
        ctx.drawImage(img, 0, 0, ctx.canvas.width, ctx.canvas.height);
        const bounding = cnv.getBoundingClientRect();
        let x = e.clientX - bounding.left - 8;
        let y = e.clientY - bounding.top - 8;

        // if (
        //   x < 3
        //   || x > bounding.left - 3
        //   || y < 3
        //   || y > bounding.top -3
        // ){
        //   return;
        // }

        // console.log({x, 'bounding.left': bounding.left });
        if ( e.clientX > ((bounding.left + bounding.width) - 20 ) ) {
          x = x - 5;
        }
        if (x < 10 ) {
          x = x + 10;
        }
        if (y < 20) {
          y = y + 35;
        }

        const pixel = ctx.getImageData(x, y, 1, 1);
        const data = pixel.data;

        // const rgba = `rgba(${data[0]}, ${data[1]}, ${data[2]}, ${data[3] / 255})`;
        const rgba = `rgba(${data[0]}, ${data[1]}, ${data[2]}, ${data[3] / 255})`;
        const pmt = pixelMapTypes.find(pmt => pmt?.pmtype_id === parseInt(pMFilters?.pixelMapType));

        colorMap = pmt?.pmcolors?.PMcolors;

        const value = colorMap.find(
           m => m[0] === data[0] && m[1] === data[1] && m[2] === data[2]
        );
        ctx.fillStyle = '#222';
       //  console.log(rgba, value, pixelMapData);
        // ctx.fillText(rgba, x, y);
        // document.getElementsByClassName('navbar-col')[0].setAttribute('style', 'background-color: ' + rgba);
        ctx.font = "13px Arial";
        ctx.shadowColor="black";
        ctx.shadowBlur=5;
        ctx.lineWidth=3;
        ctx.shadowBlur=0;
        ctx.fillStyle="white";
        if (data?.[0] === 150 && data?.[1] === 150 && data?.[2] === 150) {
          // ctx.strokeText("HELLO",25,100);
          // ctx.fillText("HELLO",25,100);
          ctx.strokeText('Clouds', x, y);
          ctx.fillText('Clouds', x, y);
        } else {
          ctx.strokeText(value && value[3].toFixed(2) || '', x, y);
          ctx.fillText(value && value[3].toFixed(2) || '', x, y);
        }
      });

      cnv.mouseout = ((e) => {
        // console.log('LEFT');
      });
    };

    USGSOverlay.prototype.onRemove = function() {
      this.cnv_.parentNode.removeChild(this.cnv_);
    };


    function createPolygonFromRectangle(rectangle) {
      const coords = [{
        lat: rectangle.getBounds().getNorthEast().lat(),
        lng: rectangle.getBounds().getNorthEast().lng()
      },
        {
          lat: rectangle.getBounds().getNorthEast().lat(),
          lng: rectangle.getBounds().getSouthWest().lng()
        },
        {
          lat: rectangle.getBounds().getSouthWest().lat(),
          lng: rectangle.getBounds().getSouthWest().lng()
        },
        {
          lat: rectangle.getBounds().getSouthWest().lat(),
          lng: rectangle.getBounds().getNorthEast().lng()
        }
      ];

      // Construct the polygon.
      var rectPoly = new google.maps.Polygon({
        path: coords,
        draggable: false,
        rotation: 0

      });
      var properties = ["strokeColor", "strokeOpacity", "strokeWeight", "fillOpacity", "fillColor"];
      //inherit rectangle properties
      var options = {};
      properties.forEach(function(property) {
        if (rectangle.hasOwnProperty(property)) {
          options[property] = rectangle[property];
        }
      });
      rectPoly.setOptions(options);

      rectangle.setMap(null);
      rectPoly.setMap(map);
      return rectPoly;
    }
  }, [map, overlay, pixelMapData]);

  const handleActiveMarker = (marker) => {
    if (activeMarker?.latitude === marker.latitude && activeMarker?.longitude === marker.longitude) {
      return;
    }
    setActiveMarker(marker);
  };

  const lastSensorValues = sensorData ? sensorData?.[sensorData.length - 1] : null;

  return (
    <LoadScript
      libraries={['drawing', 'geometry']}
      googleMapsApiKey="AIzaSyBYCV6nakW4zHapbRNzEUiCEMoaW10KZJ4"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        onLoad={(map) => {
          setMap(map);
        }}
        zoom={17}
        options={{
          fullscreenControl: true,
          streetViewControl: false,
          mapTypeId: 'hybrid',
          mapTypeControl: false,
          scrollwheel: false,
          // tilt:0,
          // panControl: false,
          keyboardShortcuts: false,
        }}
      >
        {/*{sensors.map(({ latitude, longitude } = {}) => (*/}
        {/*  <Marker*/}
        {/*    key={`${latitude}-${longitude}`}*/}
        {/*    position={({ lat: latitude, lng: longitude })}*/}
        {/*  >*/}
        {/*    {( activeMarker.latitude === latitude && activeMarker.longitude === longitude ) ? (*/}
        {/*      <InfoWindow onCloseClick={() => setActiveMarker(null)}>*/}
        {/*        <div>aaaa</div>*/}
        {/*      </InfoWindow>*/}
        {/*    ) : null}*/}
        {/*  </Marker>*/}
        {/*))}*/}
        {sensors.map(marker => (
          <Marker
            key={`${marker.latitude}-${marker.longitude}`}
            position={({ lat: marker.longitude, lng: marker.latitude })}
            onClick={() => handleActiveMarker(marker)}
            icon={{
              url: sensorIcon,
              fillColor: '#EB00FF',
              scaledSize: window?.google?.maps && new window.google.maps.Size(28,28)
            }}
          >
            {( activeMarker && activeMarker.latitude === marker.latitude && activeMarker.longitude === marker.longitude ) ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div>
                  <h5>{t('Soil moisture sensor')}</h5>
                  {lastSensorValues && (
                    <div style={{ lineHeight: '20px;' }}>
                      5cm - <strong>{parseFloat(lastSensorValues?.A1_5).toFixed(2)}</strong> m<sup>3</sup>/m<sup>3</sup><br />
                      15cm - <strong>{parseFloat(lastSensorValues?.A2_15).toFixed(2)}</strong> m<sup>3</sup>/m<sup>3</sup><br />
                      25cm - <strong>{parseFloat(lastSensorValues?.A3_25).toFixed(2)}</strong> m<sup>3</sup>/m<sup>3</sup><br />
                      35cm - <strong>{parseFloat(lastSensorValues?.A4_35).toFixed(2)}</strong> m<sup>3</sup>/m<sup>3</sup><br />
                      45cm - <strong>{parseFloat(lastSensorValues?.A5_45).toFixed(2)}</strong> m<sup>3</sup>/m<sup>3</sup><br />
                      55cm - <strong>{parseFloat(lastSensorValues?.A6_55).toFixed(2)}</strong> m<sup>3</sup>/m<sup>3</sup><br />
                    </div>
                  )}
                </div>
              </InfoWindow>
            ) : null}
          </Marker>
        ))}

        {initialPolygonPaths && (
          <Polygon
            editable={false}
            draggable={false}
            paths={initialPolygonPaths}
            options={{
              strokeOpacity: 0.6,
              fillColor: '#35ba4f',
              strokeColor: '#0000cc',
              strokeWeight: 3,
            }}
          />
        )}

        { /* Child components, such as markers, info windows, etc. */ }
        <DrawingManager
          drawingMode={drawingMode}
          onPolygonComplete={(newPolygon) => {
            setPolygon(newPolygon);
            // newPolygon.setMap(null);
            setDrawingMode(null);
            setIsDrawing(false);
          }}
          options={{
            drawingControl: false,
            circleOptions: {
              visible: false,
            },
            polygonOptions: {},
          }}
          // onPolygonComplete={onPolygonComplete}
        />
        {/*<button style={{*/}
        {/*  zIndex: 50,*/}
        {/*  color: 'red',*/}
        {/*  position: 'relative',*/}
        {/*}}*/}
        {/*  onClick={() => {*/}
        {/*    if (navigator.geolocation) {*/}
        {/*      navigator.geolocation.getCurrentPosition(*/}
        {/*        (position) => {*/}
        {/*          const pos = {*/}
        {/*            lat: position.coords.latitude,*/}
        {/*            lng: position.coords.longitude,*/}
        {/*          };*/}

        {/*          map.setCenter(pos);*/}
        {/*        },*/}
        {/*        () => {*/}
        {/*          console.log('error');*/}
        {/*        }*/}
        {/*      );*/}
        {/*    }*/}
        {/*  }}*/}
        {/*>Center</button>*/}
      </GoogleMap>

      {colorMap && (
        <div className="mx-4 my-3 color-map__container">
          {pMFilters?.pixelMapType && pixelMapTypes?.[pMFilters?.pixelMapType]?.name !== 'TRUE COLOR' && (
            <>
              <div className="color-map">
                {colorMap.map && colorMap?.map((cm, idx)=> (
                  <div
                    title={cm[3]}
                    className="color-map__item"
                    key={idx}
                    style={{
                      'backgroundColor': `rgb(${cm[0]}, ${cm[1]}, ${cm[2]})`,
                      'width': `${100/colorMap.length}%`,
                    }}
                  >
                  </div>
                ))}
              </div>

              <div className="d-flex">
                <div>{pixelMapTypes?.[pMFilters?.pixelMapType]?.lowlabel}</div>
                <div className="ms-auto">{pixelMapTypes?.[pMFilters?.pixelMapType]?.highlabel}</div>
              </div>
            </>
          )}
        </div>
      )}
    </LoadScript>
  )
}

export default memo(FLMap)
