import React, { useEffect } from  'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateOperationDS, updateTransactionDS } from '../dataServices/operations';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import toastr from 'toastr';

import { fetchOperationDS } from '../dataServices/operations';
import ContentHeader from './../layouts/components/ContentHeader';
import OperationTransactionForm from './../components/forms/OperationTransactionForm';

const OperationEditView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { operationId } = useParams();
  const [searchParams] = useSearchParams();
  const farmlandId = searchParams.get('farmlandId');
  const growingSeasonId = searchParams.get('growingSeasonId');
  const operation = useSelector((state) => state?.app?.operations?.[operationId]);

  useEffect(() => {
    dispatch(fetchOperationDS(operationId));
  }, [operationId]);

  const onSubmit = async (values) => {
    if (values?.type === 'operation') {
      return updateOperation(values);
    } else {
      return updateTransaction(values);
    }
  }

  const updateTransaction = (values) => {
    return dispatch(updateTransactionDS(values))
      .then(data => {
        if (data.success && data?.growingSeason?.season_id) {
          navigate(`/transactions/`);
          return;
        } else {
          if (data?.detail) {
            toastr.error(data?.detail);
          }
        }
        return data;
      })
      .catch(err => {
        console.log('ERROR #', err);
      });
  }

  const updateOperation = (values) => {
    return dispatch(updateOperationDS(values))
      .then(data => {
        if (data.success && data?.data?.operation_id) {
          navigate(`/operations/`);
          return;
        } else {
          if (data?.detail) {
            toastr.error(data?.detail);
          }
        }
        return data;
      })
      .catch(err => {
        console.log('ERROR #', err);
      });
  }

  return (
    <>
      <ContentHeader title="Edit Operation" />

      <section className="page">
        { operation && (
          <OperationTransactionForm
            initialValues={{
              type: 'operation',
              ...operation,
              operationfiles: operation?.operationfiles.map(f => f.fileresource),
            }}
            onSubmit={onSubmit}
            isCreate={false}
          />
        )}
      </section>
    </>
  )
};

export default OperationEditView;
