import axiosClient from '../utils/axios';
import toastr from 'toastr';


export const updateProfileDS = (values) =>  () => new Promise(async resolve =>  {
  if (!values.pk) {
    return;
  }

  const formData = new FormData();
  Object.keys(values).forEach(key => {
    formData.append(key, values[key]);
  })

  if (values?.avatar?.[0] && values.avatar[0].arrayBuffer) {
    const arrayBuffer = await values.avatar[0].arrayBuffer();
    const blob = new Blob([new Uint8Array(arrayBuffer)], {type: values.avatar[0].type });
    formData.set('avatar', blob, values.avatar[0].name);
  }

  if (typeof values?.avatar === 'string' || values?.avatar === null) {
    formData.delete('avatar')
  }

  axiosClient
    // .put(`/auth/user/`, formData, {
    .put(`/auth/loginuser/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(() => {
      toastr.success('Changes saved successfully!');
      window.location.reload();
      resolve();
    })
    .catch(function (err) {
      if (err?.response?.data) {
        resolve (err.response.data);
      }
    });
});

