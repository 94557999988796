import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Marker, StaticGoogleMap } from 'react-static-google-map';
import { getCenter } from 'geolib';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';

import { Link } from "react-router-dom";
import {
  EllipsisHorizontalCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline'
import { Card, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';

const FarmLandListItem = ({ farmland: fl, setDeleteModalPayload, setShowDeleteModal }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let soilTypes = [];
  useSelector((state) => state?.globals?.soilTypes)
    ?.forEach(st => {
      soilTypes[st.soiltype_id] = st.name;
    });

  if(!fl) {
    return;
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  const center = getCenter(
    fl?.coordinates.coordinates[0].map(coords => (
      { latitude: coords[1], longitude: coords[0] }
    )));
  const paths = fl?.coordinates.coordinates.map(ca => ca.map(cb => `${cb[1]},${cb[0]}`).join('|'))[0];
  const imgURL = `https://maps.googleapis.com/maps/api/staticmap?size=600x600&maptype=satellite&sensor=false&path=color%3a0x0000cc|weight:3|fillcolor%3a0x35ba4f|${paths}&key=AIzaSyBYCV6nakW4zHapbRNzEUiCEMoaW10KZJ4`;

  return (
    <li
      className="farmland-list-item col-12 col-md-6 col-lg-4 mb-3"
    >
      <Card className="w-100" key={fl.farmland_id}>
        <Card.Body className="">
          <div className="farmland-list-item__map-container">
            <Link
              to={`/farm-lands/${fl.farmland_id}`}
              style={{ backgroundImage: `url(${imgURL})`}}
            >
              {/*<StaticGoogleMap*/}
              {/*  apiKey="AIzaSyBYCV6nakW4zHapbRNzEUiCEMoaW10KZJ4"*/}
              {/*  center={`${center.latitude},${center.longitude}`}*/}
              {/*  zoom="16"*/}
              {/*  maptype="satellite"*/}
              {/*  size="500x500"*/}
              {/*>*/}
              {/*  <Marker color="blue" label="S" location={[11211, 11206, 11222]} />*/}
              {/*</StaticGoogleMap>*/}
            </Link>
          </div>

          <div className="">
            <div className="">
              <div className="w-75 me-5 pe-5">
                <div className="row">
                  <div className="farmland-list-item__info-item col-12 my-3">
                    {/*<span className="label">{t('Name')}</span>*/}

                    <span className="value">
                      <Link className="h3farmland-list-item col-12 col-md-6 col-lg-4 mb-3" to={`/farm-lands/${fl.farmland_id}`}>
                        {fl.name}
                      </Link>
                    </span>
                  </div>

                  <div className="farmland-list-item__info-item col-12 mb-3">
                    <span className="label">{t('Soil type')}</span>
                    <span className="value">{soilTypes?.[fl?.soiltype]}</span>
                  </div>

                  <div className="farmland-list-item__info-item col-12 mb-3">
                    <span className="label">{t('Area')}</span>
                    <span className="value">{fl?.area} ha</span>
                  </div>

                  <div className="farmland-list-item__info-item col-12 mb-3">
                    <span className="label">{t('Perimeter')}</span>
                    <span className="value">{fl?.perimeter} m</span>
                  </div>
                </div>



              </div>

              <div className="ms-auto me-3 pe-2 d-flex flex-row align-items-center">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      Irrigated
                    </Tooltip>
                  )}
                >
                  <Icon
                    icon="material-symbols:water-drop-outline"
                    height={24}
                    color="#489BE8"
                    className={`me-2 farmland-list-item__indicator ${fl.irrigation && 'farmland-list-item__indicator--active' || ''}`}
                  />
                </OverlayTrigger>

                {fl.comments  && (
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => (
                      <Tooltip id="button-tooltip" {...props}>
                        {fl.comments}
                      </Tooltip>
                    )}
                  >
                    <InformationCircleIcon
                      height={24}
                      className="me-2 farmland-list-item__indicator farmland-list-item__indicator--active"
                    />
                  </OverlayTrigger>
                )}

                {!fl.comments  && (
                  <InformationCircleIcon
                    height={24}
                    className="me-2 farmland-list-item__indicator"
                  />
                )}

                <Dropdown
                  className="ms-auto"
                  variant="secondary"
                >
                  <Dropdown.Toggle as={CustomToggle}>
                    <EllipsisHorizontalCircleIcon height={24} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => navigate(`/farm-lands/${fl.farmland_id}/edit`)}
                      eventKey="edit"
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="delete"
                      onClick={() => {
                        setDeleteModalPayload(fl);
                        setShowDeleteModal(true);
                      }}
                    >
                      <span className="text-danger">Delete farmland</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </li>
  );
};

export default FarmLandListItem;
