import React, { useEffect }  from  'react';
import toastr from 'toastr';
import { useNavigate } from 'react-router-dom';

import LoginForm from './../components/forms/LoginForm';
import axiosClient from '../utils/axios';
import { useDispatch } from 'react-redux';
import { userLogin } from '../actions/authActions';

const LoginView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   localStorage.removeItem('auth');
  //   navigate('/');
  // });

  const onSubmit = ({ email, password }) => new Promise(async resolve => {
  delete axiosClient.defaults.headers.common["Authorization"];
  await axiosClient
    .post('/auth/login/', {
      email,
      password,
    })
    .then(({ data }) => {
      localStorage.setItem('auth', JSON.stringify(data));
      dispatch(userLogin(data));
      navigate('/growing-seasons');
      resolve();
    })
    .catch(function (err) {
      const { data } = err.response;
      const { non_field_errors } = data;
      if (non_field_errors) {
        toastr.error(non_field_errors, 'Could not log in', );
      }

      Object.keys(data).forEach(function(key, index) {
        data[key] = data[key][0] || data[key];
      });
      resolve(data);
    });
  });

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-12 col-md-7 col-lg-6 col-xl-5">
          <div className="border border-3 border-primary" />
          <div className="border-1 bg-white shadow-lg">
            <div className="rounded-bottom p-5">
              <h1 className="mb-5">Log in</h1>
              <LoginForm onSubmit={onSubmit} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default LoginView;
