import React, { useCallback }  from  'react';
import { useDropzone } from "react-dropzone";

const FileInput = props => {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    props.input.onChange(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    // accept: "image/jpeg, image/png"
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} {...props} />
      {isDragActive ? (
        <p className="drop-zone">Drop the files here ...</p>
      ) : (
        <p className="drop-zone">Drag 'n' Drop your file here, or click to select file</p>
      )}
      {props.input.value
        ? props.input.value.map && props.input.value.map(file => {
          return <div key={file.name}>{file.path}</div>;
        })
        : null}
    </div>
  );
};

const InputField = (props) => {
  const {
    label,
    type,
    input: {
      name,
      ...rest
    },
    input,
    meta: {
      touched,
      error,
      submitError,
    },
    meta,
  } = props;
  return (
    <div className="mb-3">
      <label
        htmlFor={name}
        className="form-label"
      >
        {label}
      </label>

      <FileInput {...props} />

      {(error || submitError) && touched && (
        <div className="text-danger my-1">{error || submitError}</div>
      )}
    </div>
  );
};

export default InputField;
