import React, { useEffect, useState, useCallback } from  'react';
import { format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

import { formatDate } from '../utils/utils';
import { fetchTransactionDS, deleteTransactionDS } from '../dataServices/operations';
import axiosClient from '../utils/axios';
import ContentHeader from './../layouts/components/ContentHeader';

const defaultFilters = { year: new Date().getFullYear() };

const TransactionView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalPayload, setDeleteModalPayload] = useState(null);
  const { transactionId } = useParams();
  const [growingSeason, setGrowingSeason] = useState(null);
  const [farmLand, setFarmLand] = useState(null);
  const transaction = useSelector((state) => state?.app?.transactions?.[transactionId]);

  useEffect(() => {
    dispatch(fetchTransactionDS(transactionId));
  }, [transactionId]);

  useEffect(() => {
    if (!transaction) {
      return;
    }
    fetchGrowingSeason();
  }, [transaction]);

  const handleFarmlandDelete = async () => {
    await dispatch(deleteTransactionDS(deleteModalPayload?.transaction_id));
    setShowDeleteModal(false);
    setDeleteModalPayload(null);
    navigate('/transactions/');
  }

  const fetchGrowingSeason = async () => {
    await axiosClient
      .get(`/se/${transaction?.season}/`)
      .then(({ data }) => {
        setGrowingSeason(data);
        axiosClient
          .get(`/fl/${data?.farmland}/`)
          .then((data) => {
            setFarmLand(data.data);
          })
          .catch(function (err) {
            return 'error';
          });
      })
      .catch(function (err) {
        return 'error';
      });
  };


  return (
    <>
      <ContentHeader
        title={(
          <div>
            Transaction
          </div>
        )}
      />

      <section className="page page--farmland-view">
        <div className="container">
          <div className="col-12 col-md-12 col-lg-6 col-xl-5">
            <div className="mb-3">
              <label>
                Title
              </label>
              <div>
                {transaction?.title}
              </div>
            </div>

            <div className="mb-3">
              <label>
                Farmland
              </label>
              <div>
                {farmLand?.name}
              </div>
            </div>

            <div className="mb-3">
              <label>
                Growing Season
              </label>
              <div>
                {growingSeason?.name}
              </div>
            </div>

            <div className="mb-3">
              <label>
                Date
              </label>
              <div>
                {formatDate(transaction?.transactiondate)}
              </div>
            </div>

            <div className="mb-3">
              <label>
                Amount
              </label>
              <div>
                {transaction?.amount} &euro;
              </div>
            </div>

            <div className="mb-3">
              <label>
                Invoice no.
              </label>
              <div>
                {transaction?.invoiceno}
              </div>
            </div>

            <div className="mb-3">
              <label>
                Supplier
              </label>
              <div>
                {transaction?.supplier}
              </div>
            </div>

            <div className="mb-3">
              <label>
                Description
              </label>
              <div>
                <p>
                  {transaction?.description || '-'}
                </p>
              </div>
            </div>

            <div className="mt-5 d-flex justify-content">
              <button
                className="btn btn-lg btn-primary"
                onClick={() => navigate(-1)}
                type="button"
              >
                Back
              </button>

              <a
                className="btn btn-outline-danger ms-auto"
                onClick={() => {
                  setShowDeleteModal(true);
                  setDeleteModalPayload(transaction);
                }}
                type="button"
              >
                Delete
              </a>

              <button
                onClick={() => navigate(`/transactions/${transactionId}/edit`)}
                className="btn btn-lg btn-primary"
                type="submit"
              >
                Edit
              </button>
            </div>
          </div>
        </div>

        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Transaction</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {'Are you sure you want to delete '}
            <strong>{deleteModalPayload?.title}</strong>
            ?
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
              Close
            </Button>

            <Button
              variant="danger"
              onClick={handleFarmlandDelete}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </>
  )
};

export default TransactionView;
