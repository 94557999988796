import React  from  'react';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'

import MultipleFileInput from  './../finalFormFields/MultipleFileInput';
import TextInput from  './../finalFormFields/TextInput';

const SoilAnalysisForm = ({
  farmlandId,
  soilAnalysisProperties,
  onSubmit,
  initialValues,
  onCancel,
}) => {
  const { t } = useTranslation();
  const soilAnalPropName = (propId) => {
    return soilAnalysisProperties.find(p => p.soilanalysis_property_id === propId)?.name
  };

  if (!soilAnalysisProperties) {
    return null;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      render={
        ({
          submitError,
          handleSubmit,
          submitting,
          pristine,
          values
        }) => {
          // console.log({ values });
          return (
            <form onSubmit={handleSubmit}>
              <Field
                component={TextInput}
                label={t('Analysis date')}
                name="analysisdate"
                type="date"
              />

              <fieldset className="form-group my-4 p-3 bg-light">
                <legend className="h5">{t('Analysis data')}</legend>
                <FieldArray name="soilanalysis_values">
                  {({ fields }) => {
                    return (
                    <div>
                      {fields.map((name, index) => {
                        const soilAnalysisProperty = values?.soilanalysis_values?.[index]?.soilanalysis_property;
                        return (
                          <div key={name}>
                            <div>
                              <Field
                                component={TextInput}
                                name={`${name}.soilanalysis_property`}
                                type="hidden"
                              />
                              <div className="row">
                                <div className="col-12 col-md-6 col-lg-5">
                                  <Field
                                    label={soilAnalPropName(soilAnalysisProperty)}
                                    suffix="%"
                                    component={TextInput}
                                    name={`${name}.value`}
                                    type="text"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    );
                  }}
                </FieldArray>
              </fieldset>

              <Field
                component={MultipleFileInput}
                label={t('Attachments')}
                farmlandId={farmlandId}
                name="fileresource"
                isFarmLand
                isMultiple={false}
                maxFiles={1}
              />

              {submitError && <div className="error">{submitError}</div>}

              <div className="mt-5 d-flex justify-content-end">
                <button
                  className="btn btn-lg btn-default"
                  onClick={onCancel}
                  type="button"
                  disabled={submitting}
                >
                  {t('Cancel')}
                </button>

                <button
                  className="btn btn-lg btn-primary"
                  type="submit"
                  disabled={submitting || pristine}
                >
                  {t('Submit')}
                </button>
              </div>
            </form>
          );
        }}
    />
  );
};

export default SoilAnalysisForm;
