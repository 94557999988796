import React, { useEffect, useState } from  'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchCurrentUserDS } from '../dataServices/users';
import { updateProfileDS } from '../dataServices/profile';

import ContentHeader from './../layouts/components/ContentHeader';
import ProfileSettingsForm from './../components/forms/ProfileSettingsForm';

const ProfileView = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const userData = useSelector((state) => state?.auth?.user);

  useEffect(() => {
    dispatch(fetchCurrentUserDS(userData.pk)).then(() => {
      setIsLoading(false);
    });
  }, []);

  const onSubmit = (values) => {
    return dispatch(updateProfileDS(values));
  }

  return (
    <>
      <ContentHeader title="Profile settings" />

      <section className="page">
        <div className="container">
          {isLoading && 'Loading...'}

          {!isLoading && (
            <div className="col-12 col-md-12 col-lg-8 col-xl-6">
              <ProfileSettingsForm
                initialValues={{
                  ...userData,
                }}
                avatar_value={userData.avatar}
                onSubmit={onSubmit}
              />
            </div>
          )}
        </div>
      </section>
    </>
  )
};

export default ProfileView;
