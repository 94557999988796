import React, { useEffect, useState } from  'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchCurrentUserDS } from '../dataServices/users';
import {
  fetchCropTypes,
  fetchLanguages,
  fetchMetricSystems,
  fetchPixelMapTypes,
  fetchOperationTypes,
  fetchSoilTypes,
  fetchCountries,
  fetchUserCategories,
  fetchIrrigationTypes,
  fetchCultivationMethods,
  fetchWorkSpaces,
  fetchGenders,
} from '../dataServices/global';

const RequireAuth = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAuthed, setIsAuthed] = useState(false);

  useEffect(() => {
    const authData = localStorage.getItem('auth');
    if (authData) {
      const { pk } = JSON.parse(authData).user;
      const onLoaded = (lang) => {
        i18n.changeLanguage(lang);
      }
      dispatch(fetchCurrentUserDS(pk, onLoaded)).then(() => {
        setIsAuthed(true);
      });
      fetchGlobalData();
    } else {
      navigate('/login');
    }
  }, []);

  const fetchGlobalData = () => {
    dispatch(fetchWorkSpaces());
    dispatch(fetchCropTypes());
    dispatch(fetchLanguages());
    dispatch(fetchMetricSystems());
    dispatch(fetchGenders());
    dispatch(fetchPixelMapTypes());
    dispatch(fetchOperationTypes());
    dispatch(fetchIrrigationTypes());
    dispatch(fetchUserCategories());
    dispatch(fetchCultivationMethods());
    dispatch(fetchSoilTypes());
    dispatch(fetchCountries());
  };

  return (
    <>
      {isAuthed && <Outlet />}
    </>
  )
};

export default RequireAuth;
