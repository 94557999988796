import React, { useEffect, useState, useCallback } from  'react';
import { format } from 'date-fns'
import toastr from 'toastr';
import axiosClient from '../utils/axios';
import { Modal, Tabs, Tab, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';

import SoilAnalysisForm from './../components/forms/SoilAnalysisForm';
import {
  fetchFarmLandDS,
  fetchFarmLandGrowingSeasonsDS,
  fetchPMFilterDates,
  fetchPixelMapData,
  resetPixelMapData,
} from '../dataServices/farmLands';
import {
  PIXEL_MAP_DATA_FETCHED,
} from "../actionTypes/actionTypes";
import { Card } from 'react-bootstrap';import {
  DocumentIcon
} from '@heroicons/react/24/outline';


import { formatDate } from '../utils/utils';
import FLMap from '../components/FLMap';
import WeatherWidget from '../components/WeatherWidget';
import PixelMapFiltersForm from '../components/forms/PixelMapFiltersForm';
import ContentHeader from './../layouts/components/ContentHeader';

const defaultFilters = { year: new Date().getFullYear() };

const FarmLandView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [soilAnalysisProperties, setSoilAnalysisProperties] = useState([]);
  const [pMFilters, setPMFilters] = useState(defaultFilters);
  const [isLoading, setIsLoading] = useState(true);
  const [showSoilAnalysisModal, setShowSoilAnalysisModal] = useState(false);
  const [isLoadingPMData, setIsLoadingPMData] = useState(false);
  const [soilAnalysis, setSoilAnalysis] = useState(null);
  const { farmlandId } = useParams();
  const farmland = useSelector((state) => state?.app?.farmLand?.[farmlandId]);
  const farmlandGrowingSeasons = useSelector((state) => state?.app?.farmLandGrowingSeasons?.[farmlandId]);
  const pixelMapData = useSelector((state) => state?.app?.pixelMapData);
  const pixelMapTypes = useSelector((state) => state?.globals?.pixelMapTypes);
  let cropTypes = [];
  let colorMap;
  useSelector((state) => state?.globals?.cropTypes)
    ?.forEach(st => {
      cropTypes[st.croptype_id] = st.name;
    });

  const onSoilAnalysisFormSubmit = (values) => new Promise(async resolve => {
    // if (values.operation_id) {
    //   return dispatch(updateOperationDS(values))
    //     .then(async data => {
    //       if (data.success) {
    //         // navigate(`/operations/`);
    //         setShowOperationModal(false);
    //         setSelectedDate(new Date(values.operationdate));
    //         return;
    //       } else {
    //         if (data?.detail) {
    //           toastr.error(data?.detail);
    //         }
    //       }
    //       return data;
    //     })
    //     .catch(err => {
    //       console.log('ERROR #', err);
    //     });
    // } else {
    const _data = {
      ...values,
      fileresource: values?.fileresource?.[0]?.fileresource_id,
    };


    const soilanalysis_values = [];
    values?.soilanalysis_values?.forEach(v => {
      if (v.value) {
        soilanalysis_values.push({
          soilanalysis_property: v.soilanalysis_property,
          value: v.value,
        });
      }
    });
    _data.soilanalysis_values =  soilanalysis_values;

    await axiosClient
      .post('fl/soilanalysis/', _data)
      .then(async (res) => {
        const { data, status } = res;
        if (status === 201) {
          setShowSoilAnalysisModal(false);
          toastr.success('Soil analysis data submitted successfully!');
          axiosClient.get(
            `fl/soilanalysis/?farmland_id=${farmlandId}`
          )
            .then(
              ({ data: { results } }) => { setSoilAnalysis(results.sort((a, b) => b.analysisdate - a.analysisdate)); }
            );
          resolve();
        } else {
          toastr.error('Could not submit data');
          resolve();
        }
        resolve();
      })
      .catch(function (err) {
        const { data } = err.response;
        const { non_field_errors } = data;
        if (non_field_errors) {
          toastr.error(non_field_errors, 'Error');
        }

        Object.keys(data).forEach(function(key, index) {
          data[key] = data[key][0] || data[key];
        });


        resolve(data);
      });
  });

  const { pixelMapType } = pMFilters;

  if (pixelMapType) {
    const pmt = pixelMapTypes.find(pmt => parseInt(pmt.pmtype_id) === parseInt(pixelMapType));
    colorMap = pmt?.pmcolors?.PMcolors;
  }

  const extractName = (f) => {
    const parts = f.file.split("/");
    return parts[parts.length - 1];
  }

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(fetchFarmLandDS(farmlandId)),
        dispatch(fetchFarmLandGrowingSeasonsDS(farmlandId)),
        dispatch(resetPixelMapData()),
      ]);

      axiosClient.get(
        `fl/${farmlandId}/calc/soilanalysis_property/`
        //`fl/${farmlandId}/calc/weather_forecast/`
      )
        .then(({ data }) => {
            setSoilAnalysisProperties(data);
          });

      axiosClient.get(
        `fl/soilanalysis/?farmland_id=${farmlandId}`
      )
        .then(
          ({ data: { results } }) => { setSoilAnalysis(results.sort((a, b) => b.analysisdate - a.analysisdate)); }
        );
    };
    fetchFilterDates();
    fetchData();
    setIsLoading(false);
  }, [farmlandId]);


  const fetchFilterDates = useCallback((year = pMFilters.year) => {
    dispatch(fetchPMFilterDates(farmlandId, year));
  }, [pMFilters.year]);

  const onPMFiltersChange = filters => {
    setPMFilters(filters);
    setIsLoadingPMData(true);

    if (!filters.snapshotDateTime) {
      dispatch({
        type: PIXEL_MAP_DATA_FETCHED,
        payload: null,
      });
      setIsLoadingPMData(false);
      return;
    }

    dispatch(fetchPixelMapData({
      farmLandId: farmlandId,
      pmType: filters.pixelMapType,
      fromDate: format(new Date(filters.snapshotDateTime),'yyyy-MM-dd'),
      toDate: format(new Date(filters.snapshotDateTime),'yyyy-MM-dd'),
    }))
      .then(() => setIsLoadingPMData(false));
  };

  let soilTypes = [];
  useSelector((state) => state?.globals?.soilTypes)
    .forEach(st => {
      soilTypes[st.soiltype_id] = st.name;
    });

  let irrigationTypes = [];
  useSelector((state) => state?.globals?.irrigationTypes)
    ?.forEach(st => {
      irrigationTypes[st.Irrigationtype_id] = st.name;
    });

  return (
    <>
      <ContentHeader
        title={(
          <div>
            {farmland && farmland.name || '-'}
          </div>
        )}
      />

      <section className="page page--farmland-view">
        {isLoading && (
          <div
            className="col-12 text-center"
          >
            Loading...
          </div>
        )}

        {!isLoading && farmland && (
          <>
          <div className="position-relative p-0">
            {isLoadingPMData && (
              <div className="pm-loading-indicator">
                <div>
                  Loading data...
                </div>
              </div>
            )}

            <PixelMapFiltersForm
              onYearChange={(year) => {
                setPMFilters({
                  ...pMFilters,
                  year,
                });
                fetchFilterDates(year);
              }}
              initialValues={pMFilters}
              onSubmit={onPMFiltersChange}
            />

            <FLMap
              farmLand={farmland?.coordinates}
              pixelMapData={pixelMapData}
              pMFilters={pMFilters}
              colorMap={colorMap}
            />
          </div>


          {/*<div className="d-flex mt-4">*/}
          {/*  <div className="ms-auto px-4">*/}
          {/*    <Link*/}
          {/*      className="btn btn-primary ms-auto"*/}
          {/*      to={`/farm-lands/${farmlandId}/edit`}*/}
          {/*    >*/}
          {/*      Edit Farmland*/}
          {/*    </Link>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="container">
            <section className="mt-5">
              <h2 className="mb-4">{t('Details')}</h2>

              <Card>
                <Card.Body className="pb-0">
                  <div className="spec-list row">
                    <div className="spec-list__item col-6 col-lg-3 mb-3">
                      <span className="label">{t('Farmland')}</span>
                      <span className="value">{farmland?.name}</span>
                    </div>

                    <div className="spec-list__item col-6 col-lg-3 mb-3">
                      <span className="label">{t('Soil Type')}</span>
                      <span className="value">{soilTypes?.[farmland?.soiltype]}</span>
                    </div>

                    <div className="farmland-list-item__info-item col-6 col-lg-3 mb-3">
                      <span className="label">{t('Area')}</span>
                      <span className="value">{farmland?.area} ha</span>
                    </div>

                    <div className="farmland-list-item__info-item col-6 col-lg-3 mb-3">
                      <span className="label">{t('Perimeter')}</span>
                      <span className="value">{farmland?.perimeter} m</span>
                    </div>

                    <div className="farmland-list-item__info-item col-6 col-lg-3 mb-3">
                      <span className="label">{t('Elevation')}</span>
                      <span className="value">{farmland?.elevation} m</span>
                    </div>

                    <div className="farmland-list-item__info-item col-6 col-lg-3 mb-3">
                      <span className="label">{t('Slope')}</span>
                      <span className="value">
                        {/*{farmland?.slope}. Latitude: {farmland?.slope_lat}, Longitude: {farmland?.slope_lon}*/}
                        {farmland?.slope}
                      </span>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </section>

            <section className="mt-5">
              <h2 className="mb-4">{t('Cultivations')}</h2>

              <Card>
                <Card.Body className="p-4">
                  {farmlandGrowingSeasons?.length === 0 && (
                    <div className="text-center">
                      <i className="text-muted">No growing season added yet.</i>
                    </div>
                  )}

                  {farmlandGrowingSeasons?.length > 0 && (
                    <table className="table">
                      <thead>
                      <tr>
                        <th scope="col">{t('Name')}</th>
                        <th scope="col">{t('Crop')}</th>
                        <th scope="col">{t('Crop period')}</th>
                      </tr>
                      </thead>

                      <tbody>
                      {farmlandGrowingSeasons?.length > 0 && farmlandGrowingSeasons.map(gs => (
                        <tr key={gs.season_id}>
                          <td>
                            <Link to={`/growing-seasons/${gs.season_id}`}>
                              {gs.name}
                            </Link>
                          </td>
                          <td>{cropTypes[gs.croptype]}</td>
                          <td>{gs.cropperiod_name}</td>
                        </tr>
                      ))}

                      </tbody>
                    </table>
                  )}

                  <div className="d-flex">
                    <Link className="btn btn-primary mt-3 mx-auto" to={`/growing-seasons/add?farmlandId=${farmlandId}`}>
                      {t('New Growing Season')}
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </section>

            <div className="row my-5">
              <div className="col-12 col-lg-6 col-xl-4">
                <section>
                  <h2 className="mb-4">{t('Weather')}</h2>
                  <Card>
                    <Card.Body>
                      <WeatherWidget
                        locationLabel={farmland?.name}
                        coordinates={{
                          latitude: farmland?.coordinates?.coordinates?.[0][0][0],
                          longitude: farmland?.coordinates?.coordinates?.[0][0][1],
                        }}
                      />
                    </Card.Body>
                  </Card>
                </section>
              </div>

              <div className="col-12 col-lg-6  col-xl-8 mt-5 mt-lg-0">
                <div className="d-flex flex-column h-100">
                  <h2 className="mb-4">{t('Soil Analysis')}</h2>
                  <Card className="flex-grow-1 ">
                    <Card.Body>
                      {(soilAnalysis && soilAnalysis?.length === 0)  && (
                        <div className="d-flex align-items-center justify-content-center flex-column h-100">
                          <i className="text-muted">{t(`You haven't submitted any soil analysis data yet`)}</i>

                          <Button
                            className="mt-4 btn btn-primary"
                            onClick={() => {
                              setShowSoilAnalysisModal(true);
                            }}
                          >
                            {t('Add soil analysis data')}
                          </Button>
                        </div>
                      )}

                      {soilAnalysis && soilAnalysis.length > 0 && (
                        <>
                          <div className="d-flex w-100">
                            <Button
                              className="btn btn-primary ms-auto"
                              onClick={() => {
                                setShowSoilAnalysisModal(true);
                              }}
                            >
                              {t('Add soil analysis data')}
                            </Button>
                          </div>

                          <Tabs
                            defaultActiveKey={soilAnalysis?.[0]?.soilanalysis_id}
                            id="soli-analysis"
                            className="mb-3"
                          >
                            {soilAnalysis && soilAnalysis.map(a => {
                              return (
                                <Tab
                                  eventKey={a.soilanalysis_id}
                                  title={new Date(a.analysisdate).getFullYear()}
                                  key={a.soilanalysis_id}
                                >
                                  <div className="spec-list__item col-6 col-lg-3 mb-3">
                                    <span className="label text-muted">{t('Analysis date')}</span>
                                    <span className="value">{formatDate(a.analysisdate)}</span>
                                  </div>

                                  <div className="spec-list row pt-3">
                                    {a?.soilanalysis_values
                                      && a?.soilanalysis_values.map(({
                                       soilanalysis_property: { name },
                                       value,
                                     }) => (
                                      <div className="spec-list__item col-6 col-lg-3 mb-3 px-3">
                                        <span className="label text-muted">{name}</span>
                                        <span className="value">{value}%</span>
                                      </div>
                                    ))}
                                  </div>

                                  <hr />
                                  <a
                                    href={a?.fileresource?.file}
                                    target="_blank"
                                    className="d-inline-flex  rounded  align-items-center"
                                  >
                                    <DocumentIcon
                                      className="me-1"
                                      width={35}
                                    />
                                    {extractName(a?.fileresource)}
                                  </a>
                                </Tab>
                              )
                            })}
                          </Tabs>
                        </>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </div>

              {/*<div className="col-12 col-lg-6  col-xl-8">*/}
              {/*  <h2 className="mb-4">{t('Files')}</h2>*/}
              {/*  <Card>*/}
              {/*    <Card.Body>*/}
              {/*      {farmland?.farmlandfiles?.length === 0 && '-'}*/}

              {/*      {farmland?.farmlandfiles?.length > 0 && (*/}
              {/*        <ul className="attachments row p-0 mb-0">*/}
              {/*          {farmland.farmlandfiles.map((f, key)=> (*/}
              {/*            <li key={key} className="col-12 col-md-6 px-2">*/}
              {/*              {f?.fileresource?.mimetype?.includes('image') && (*/}
              {/*                <a href={f.file} target="_blank">*/}
              {/*                  <img*/}
              {/*                    style={{*/}
              {/*                      width: '100%',*/}
              {/*                      height: 'auto',*/}
              {/*                    }}*/}
              {/*                    src={f?.fileresource.file}*/}
              {/*                  />*/}
              {/*                </a>*/}
              {/*              )}*/}

              {/*              { !f?.fileresource.mimetype?.includes('image') && (*/}
              {/*                <a href={f?.fileresource.file} className="d-flex h-100 border-1 bg-light rounded p-4 flex-column align-items-center justify-content-center">*/}
              {/*                  {f?.fileresource.mimetype === 'application/pdf' && (*/}
              {/*                    <DocumentIcon*/}
              {/*                      className="mb-3"*/}
              {/*                      width={65}*/}
              {/*                    />*/}
              {/*                  )}*/}
              {/*                  {extractName(f)}*/}
              {/*                </a>*/}
              {/*              )}*/}
              {/*            </li>*/}
              {/*          ))}*/}
              {/*        </ul>*/}
              {/*      )}*/}
              {/*    </Card.Body>*/}
              {/*  </Card>*/}
              {/*</div>*/}
            </div>
          </div>
          </>
        )}
      </section>

      <Modal
        show={showSoilAnalysisModal}
        backdrop="static"
        onHide={() => setShowSoilAnalysisModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('Add soil analysis data')}
          </Modal.Title>
        </Modal.Header>
        {console.log('->', soilAnalysisProperties)}
        <Modal.Body>
          <SoilAnalysisForm
            farmlandId={farmlandId}
            soilAnalysisProperties={soilAnalysisProperties}
            // isCreate={!operation}
            initialValues={{
              farmland: farmlandId,
              soilanalysis_values: soilAnalysisProperties.map(p => ({
                soilanalysis_property: p.soilanalysis_property_id,
                value: '',
              })),
            }}
            onCancel={() => setShowSoilAnalysisModal(false)}
            onSubmit={onSoilAnalysisFormSubmit}
          />
        </Modal.Body>
      </Modal>
    </>
  )
};

export default FarmLandView;
