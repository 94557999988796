import React, { useState, useEffect } from  'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateGrowingSeasonDS } from '../dataServices/growingSeasons';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import toastr from 'toastr';
import { useTranslation } from 'react-i18next';

import { fetchGrowingSeasonDS } from '../dataServices/growingSeasons';
import ContentHeader from './../layouts/components/ContentHeader';
import GrowingSeasonForm from './../components/forms/GrowingSeasonForm';

const FarmLandCreateView = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { growingSeasonId } = useParams();
  const { t } = useTranslation();
  const growingSeason = useSelector((state) => state?.app?.growingSeason?.[growingSeasonId]);
  useEffect(() => {
    dispatch(fetchGrowingSeasonDS(growingSeasonId)).then(() => {
      setIsLoading(false);
    });
  }, []);

  const onSubmit = async (values) => {
    return dispatch(updateGrowingSeasonDS(values))
      .then(data => {
        if (data.success) {
          navigate(`/growing-seasons/${values.season_id}`);
          return;
        } else {
          if (data?.detail) {
            toastr.error(data?.detail);
          }
        }
        return data;
      })
      .catch(err => {
        console.log('ERROR #', err);
      });
  }

  return (
    <>
      <ContentHeader title={t('Edit Growing Season')} />

      <section className="page">
        <div className="container">{isLoading && 'Loading...'}</div>

        {!isLoading && (
          <GrowingSeasonForm
            initialValues={growingSeason}
            onSubmit={onSubmit}
            isCreate={false}
          />
        )}
      </section>
    </>
  )
};

export default FarmLandCreateView;
