import toastr from 'toastr';

import axiosClient from '../utils/axios';
import { DEFAULT_PAGE_SIZE } from '../constants/const';
import {
  OPERATIONS_FETCHED,
  OPERATION_FETCHED,
  TRANSACTION_FETCHED,
  TRANSACTIONS_FETCHED,
} from "../actionTypes/actionTypes";

export const fetchOperationsDS = (
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  filters = {},
) => async (dispatch) => {
  let filtersQS = '';
  if (filters?.q) {
    filtersQS += `&contains=${filters.q}`;
  }
  if (filters?.season_id) {
    filtersQS += `&season_id=${filters.season_id}`;
  }
  await axiosClient
    .get(`/operations/?page=${page}&page_size=${pageSize}${filtersQS}`)
    .then(({ data }) => {
      dispatch({
        type: OPERATIONS_FETCHED,
        payload: data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};

export const fetchTransactionsDS = (
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  filters = {},
) => async (dispatch) => {
  let filtersQS = '';
  if (filters?.q) {
    filtersQS += `&contains=${filters.q}`;
  }
  await axiosClient
    .get(`/transactions/?page=${page}&page_size=${pageSize}${filtersQS}`)
    .then(({ data }) => {
      dispatch({
        type: TRANSACTIONS_FETCHED,
        payload: data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};

export const createOperationDS = (values) =>  () => new Promise(async (resolve, reject) =>  {
  const _values = {
    ...values,
    operationtype: parseInt(values.operationtype),
    season: parseInt(values.season),
  };

  axiosClient
    .post(`/operations/`, _values)
    .then(({ data }) => {
      toastr.success('Operation added successfully!');
      resolve({ success: true, data });
    })
    .catch(function (err) {
      if (err?.response?.data) {
        resolve (err.response.data);
      }
    });
});


export const createTransactionDS = (values) =>  () => new Promise(async (resolve, reject) =>  {
  const _values = { ...values };

  axiosClient
    .post(`/transactions/`, _values)
    .then(({ data }) => {
      toastr.success('Transaction added successfully!');
      resolve({ success: true, data });
    })
    .catch(function (err) {
      if (err?.response?.data) {
        resolve (err.response.data);
      }
    });
});

export const updateOperationDS = (values) =>  () => new Promise(async (resolve, reject) =>  {
  axiosClient
    .put(`/operations/${values.operation_id}/`, values)
    .then(() => {
      toastr.success('Operation updated successfully!');
      resolve({ success: true });
    })
    .catch(function (err) {
      if (err?.response?.data) {
        resolve (err.response.data);
      }
    });
});

export const updateTransactionDS = (values) =>  () => new Promise(async (resolve, reject) =>  {
  axiosClient
    .put(`/transactions/${values.operation_id}/`, values)
    .then(() => {
      toastr.success('Transaction updated successfully!');
      resolve({ success: true });
    })
    .catch(function (err) {
      if (err?.response?.data) {
        resolve (err.response.data);
      }
    });
});

export const deleteOperationDS = (seasonId) =>  () => new Promise(async (resolve) =>
  axiosClient
    .delete(`/operations/${seasonId}/`)
    .then(() => {
      toastr.success('Operation deleted successfully!');
      resolve({ success: true });
    })
    .catch(function (err) {
      if (err?.response?.data) {
        resolve (err.response.data);
      }
    }));

export const fetchOperationDS = (id) => async (dispatch) => {
  await axiosClient
    .get(`/operations/${id}/`)
    .then(({ data }) => {
      dispatch({
        type: OPERATION_FETCHED,
        payload: data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};

export const deleteTransactionDS = (seasonId) =>  () => new Promise(async (resolve) =>
  axiosClient
    .delete(`/transactions/${seasonId}/`)
    .then(() => {
      toastr.success('Transaction deleted successfully!');
      resolve({ success: true });
    })
    .catch(function (err) {
      if (err?.response?.data) {
        resolve (err.response.data);
      }
    }));

export const fetchTransactionDS = (id) => async (dispatch) => {
  await axiosClient
    .get(`/transactions/${id}/`)
    .then(({ data }) => {
      dispatch({
        type: TRANSACTION_FETCHED,
        payload: data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};

