import React, { useState } from  'react';
import { Form, Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// import MultipleFileInput from  './../finalFormFields/MultipleFileInput';
import { stringify, parse } from 'wkt';
import DrawPolygonMap from './../DrawPolygonMap';
import KMLFileUploader from  './../KMLFileUploader';
import SelectInput from  './../finalFormFields/SelectInput';
import TextAreaInput from  './../finalFormFields/TextAreaInput';
import TextInput from  './../finalFormFields/TextInput';

const FarmLandForm = ({
  isCreate = true,
  onSubmit,
  initialValues,
}) => {
  const [KMLCoords, setKMLCoords] = useState(null);
  const soilTypes = useSelector((state) => state?.globals?.soilTypes);
  const navigate = useNavigate();
  const { t } = useTranslation();


  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{
        setPolygonCoords: (args, state, utils) => {
          let _coords = args[0].coords;
          if (!!args?.[0]?.isKML) { // is KML upload
            const parsed = parse(_coords);
            let reversed = parsed.coordinates[0];
            reversed = reversed.map(c => [c[1], c[0]]);
            _coords = stringify({
              type: 'Polygon',
              coordinates: [reversed],
            });
          }

          if (args?.[0]?.coords === '') {
            utils.changeValue(state, 'coordinates', () => '');
            return;
          }
          utils.changeValue(state, 'coordinates', () => `SRID=4326;${_coords}`);
        },
      }}
      render={
        ({
          submitError,
          handleSubmit,
          form: {
            mutators: {
              setPolygonCoords,
            }
          },
          submitting,
          pristine,
          values
        }) => {
          const onKMLFileUploaded = (args) => {
            setKMLCoords(args.coords);
            setPolygonCoords(args);
          }

          return (
            <form onSubmit={handleSubmit}>
              <div className="container">
                <div className="col-12 mb-4">
                  {KMLCoords && (
                    <DrawPolygonMap
                      allowEdit={false}
                      initialValue={{
                        coordinates: parse(KMLCoords).coordinates,
                      }}
                    />
                  )}

                  {!KMLCoords && (
                    <>
                      <DrawPolygonMap
                        initialValue={initialValues?.coordinates}
                        KMLCoords={KMLCoords}
                        onCoordsUpdate={setPolygonCoords}
                      />

                      <div className="kml-separator">
                        <span>or</span>
                      </div>
                    </>
                  )}

                  <div className="mt-3">
                    <KMLFileUploader
                      onUploadComplete={onKMLFileUploaded}
                    />
                  </div>

                  <h3 className="mt-5">{t('Farmland details')}</h3>
                  <hr />

                </div>

                <div className="col-12 col-md-12 col-lg-8 col-xl-6">
                  <Field
                    component={TextInput}
                    label={t('Name / Area')}
                    name="name"
                    type="text"
                  />

                  {/*<Field*/}
                  {/*  component={TextInput}*/}
                  {/*  label="Soil Analysis   "*/}
                  {/*  name="soilanalysis"*/}
                  {/*  type="text"*/}
                  {/*/>*/}

                  {/*<Field*/}
                  {/*  component={TextInput}*/}
                  {/*  label="Pollution"*/}
                  {/*  name="pollution"*/}
                  {/*  type="text"*/}
                  {/*/>*/}

                  {/*<Field*/}
                  {/*  component={TextInput}*/}
                  {/*  label="Prevcultivations"*/}
                  {/*  name="prevcultivations"*/}
                  {/*  type="text"*/}
                  {/*/>*/}

                  <Field
                    component={SelectInput}
                    label={t('Irrigation')}
                    name="irrigation"
                    options={[
                      { value: true, name: 'Yes'},
                      { value: false, name: 'No'},
                    ]}
                  />

                  <Field
                    component={SelectInput}
                    label={t('Soil type')}
                    name="soiltype"
                    options={soilTypes.map(st => ({
                      name: st.name,
                      value: st.soiltype_id,
                    }))}
                  />


                  <Field
                    // disabled
                    className="d-none"
                    component={TextAreaInput}
                    label="Coordinates"
                    name="coordinates"
                    // disabled
                  />


                  <Field
                    component={TextAreaInput}
                    label={t('Comments')}
                    name="comments"
                  />

                  {/*<Field*/}
                  {/*  component={MultipleFileInput}*/}
                  {/*  label={t('Attachments')}*/}
                  {/*  name="fileresource_ids"*/}
                  {/*  isFarmLand*/}
                  {/*/>*/}

                  {submitError && <div className="error">{submitError}</div>}

                  <div className="mt-5 d-flex justify-content-end">
                    <button
                      className="btn btn-lg btn-primary"
                      onClick={() => navigate('/growing-seasons')}
                      type="button"
                      disabled={submitting}
                    >
                      Cancel
                    </button>

                    <button
                      className="btn btn-lg btn-primary"
                      type="submit"
                      disabled={submitting || pristine}
                    >
                      {isCreate ? 'Add farmland' : 'Save changes'}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
    />
  );
};

export default FarmLandForm;
