import React, { useEffect, useState } from  'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { fetchFarmLandDS, updateFarmLandDS } from '../dataServices/farmLands';
import ContentHeader from './../layouts/components/ContentHeader';
import FarmLandForm from './../components/forms/FarmLandForm';

const FarmLandCreateView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state?.auth?.user);
  const [isLoading, setIsLoading] = useState(true);
  const { farmlandId } = useParams();
  const farmland = useSelector((state) => state?.app?.farmLand?.[farmlandId]);

  useEffect(() => {
    dispatch(fetchFarmLandDS(farmlandId)).then(() => {
      setIsLoading(false);
    });
  }, []);

  const onSubmit = async (values) => {
    return dispatch(updateFarmLandDS({
      ...values,
      fileresource_ids: values?.fileresource_ids?.map(f => f.fileresource_id),
    }))
      .then(data => {

        if (data?.success) {
          navigate('/growing-seasons');
        }
        return data;
      })
      .catch(err => {
        console.log('ERROR #', err);
      });
  }

  return (
    <>
      <ContentHeader title="Edit farmland" />

      <section className="page">
        <FarmLandForm
          initialValues={farmland}
          onSubmit={onSubmit}
          isCreate={false}
        />
      </section>
    </>
  )
};

export default FarmLandCreateView;
