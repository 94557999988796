import { useTranslation } from 'react-i18next';

const JSONValueInput = ({
  label,
  disabled,
  prefix = null,
  suffix = null,
  wrapperClass = 'mb-3',
  input: {
    name,
    ...rest
  },
  input,
  meta: {
    touched,
    error,
    submitError,
  },
  placeholder,
  attribute,
}) => {
  const { t } = useTranslation();
  let inputType = 'text';
  let inputStep = undefined;

  if (!attribute) {
    return;
  }

  if (attribute.type === 'float') {
    inputType = 'number';
    inputStep = 0.1;
  }

  return (
    <div className={wrapperClass}>
      {label && (
        <label
          htmlFor={name}
          className="form-label"
        >
          {label}
        </label>
      )}

      { attribute?.type === 'dropdown' && (
        <select
          className="form-control"
          onChange={e => input.onChange({value: e.target.value})}
          name={name}
          value={input && input?.value?.value}
        >
          <option value="">{t('Select an option...')}</option>

          {attribute?.values.map((v, i) => (
            <option key={`${i}-${v.id}`} value={v.id}>
              {v.name}
            </option>
          ))}
        </select>
      )}

      { attribute?.type !== 'dropdown' && (
        <input
          type={inputType}
          step={inputStep}
          className="form-control"
          onChange={e => input.onChange({ value: e.target.value })}
          name={name}
          value={input && input?.value?.value || ''}
        />
      )}

      {(error || submitError) && touched && (
        <div className="text-danger my-1">{error || submitError}</div>
      )}
    </div>
  );
};

export default JSONValueInput;
