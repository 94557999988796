export const CROP_TYPES_FETCHED = 'CROP_TYPES_FETCHED';
export const FARMLANDS_FETCHED = 'FARMLANDS_FETCHED';
export const FARMLAND_FETCHED = 'FARMLAND_FETCHED';
export const FARMLAND_SEASONS_FETCHED = 'FARMLAND_SEASONS_FETCHED';

export const GROWING_SEASONS_FETCHED = 'GROWING_SEASONS_FETCHED';
export const GROWING_SEASON_FETCHED = 'GROWING_SEASON_FETCHED';

export const OPERATIONS_FETCHED = 'OPERATIONS_FETCHED';
export const OPERATION_FETCHED = 'OPERATION_FETCHED';

export const TRANSACTIONS_FETCHED = 'TRANSACTIONS_FETCHED';
export const TRANSACTION_FETCHED = 'TRANSACTION_FETCHED';
export const OPERATION_TYPES_FETCHED = 'OPERATION_TYPES_FETCHED';

export const CULTIVATION_METHODS_FETCHED = 'CULTIVATION_METHODS_FETCHED';
export const WORKSPACES_FETCHED = 'WORKSPACES_FETCHED';
export const COUNTRIES_FETCHED = 'COUNTRIES_FETCHED';
export const LANGUAGES_FETCHED = 'LANGUAGES_FETCHED';
export const METRIC_SYSTEMS_FETCHED = 'METRIC_SYSTEMS_FETCHED';
export const GENDERS_FETCHED = 'GENDERS_FETCHED';
export const IRRIGATION_TYPES_FETCHED = 'IRRIGATION_TYPES_FETCHED';
export const PIXEL_MAP_SNAPSHOTS_FETCHED = 'PIXEL_MAP_SNAPSHOTS_FETCHED';
export const PIXEL_MAP_DATA_RESET = 'PIXEL_MAP_DATA_RESET';
export const PIXEL_MAP_DATA_FETCHED = 'PIXEL_MAP_DATA_FETCHED';
export const PIXEL_MAP_TYPES_FETCHED = 'PIXEL_MAP_TYPES_FETCHED';
export const SOIL_TYPES_FETCHED = 'SOIL_TYPES_FETCHED';
export const USER_CATEGORIES_FETCHED = 'USER_CATEGORIES_FETCHED';
export const USER_FETCHED = 'USER_FETCHED';
export const USER_LOGIN = 'USER_LOGIN';
