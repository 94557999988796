import React, { useEffect, useState, useCallback } from  'react';
import { format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { formatDate } from '../utils/utils';
import { fetchOperationDS, deleteOperationDS } from '../dataServices/operations';
import axiosClient from '../utils/axios';
import ContentHeader from './../layouts/components/ContentHeader';

const defaultFilters = { year: new Date().getFullYear() };

const OperationView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalPayload, setDeleteModalPayload] = useState(null);
  const { operationId } = useParams();
  const [growingSeason, setGrowingSeason] = useState(null);
  const [farmLand, setFarmLand] = useState(null);
  const operation = useSelector((state) => state?.app?.operations?.[operationId]);

  useEffect(() => {
    dispatch(fetchOperationDS(operationId));
  }, [operationId]);

  useEffect(() => {
    if (!operation) {
      return;
    }
    fetchGrowingSeason();
  }, [operation]);

  const handleFarmlandDelete = async () => {
    await dispatch(deleteOperationDS(deleteModalPayload?.operation_id));
    setShowDeleteModal(false);
    setDeleteModalPayload(null);
    navigate('/operations/');
  }

  const fetchGrowingSeason = async () => {
    await axiosClient
      .get(`/se/${operation?.season}/`)
      .then(({ data }) => {
        setGrowingSeason(data);
        axiosClient
          .get(`/fl/${data?.farmland}/`)
          .then((data) => {
            setFarmLand(data.data);
          })
          .catch(function (err) {
            return 'error';
          });
      })
      .catch(function (err) {
        return 'error';
      });
  };


  return (
    <>
      <ContentHeader
        title={(
          <div>
            {t('Operation')}
          </div>
        )}
      />

      <section className="page page--farmland-view">
        <div className="container">
          <div className="col-12 col-md-12 col-lg-6 col-xl-5">
            <div className="mb-3">
              <label>
                {t('Title')}
              </label>
              <div>
                {operation?.title}
              </div>
            </div>

            <div className="mb-3">
              <label>
                {t('Farmland')}
              </label>
              <div>
                {farmLand?.name}
              </div>
            </div>

            <div className="mb-3">
              <label>
                {t('Growing Season')}
              </label>
              <div>
                {growingSeason?.name}
              </div>
            </div>

            <div className="mb-3">
              <label>
                {t('Date')}
              </label>
              <div>
                {formatDate(operation?.operationdate)}
              </div>
            </div>

            <div className="mb-3">
              <label>
                {t('Description')}
              </label>
              <div>
                <p>
                  {operation?.description || '-'}
                </p>
              </div>
            </div>

            <div className="mt-5 d-flex justify-content">
              <button
                className="btn btn-lg btn-primary"
                onClick={() => navigate(-1)}
                type="button"
              >
                Back
              </button>

              <a
                className="btn btn-outline-danger ms-auto"
                onClick={() => {
                  setShowDeleteModal(true);
                  setDeleteModalPayload(operation);
                }}
                type="button"
              >
                {t('Delete')}
              </a>

              <button
                onClick={() => navigate(`/operations/${operationId}/edit`)}
                className="btn btn-lg btn-primary"
                type="submit"
              >
                {t('Edit')}
              </button>
            </div>
          </div>
        </div>

        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Operation</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {'Are you sure you want to delete '}
            <strong>{deleteModalPayload?.title}</strong>
            ?
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
              Close
            </Button>

            <Button
              variant="danger"
              onClick={handleFarmlandDelete}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </>
  )
};

export default OperationView;
