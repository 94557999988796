import { DEFAULT_DATE_FORMAT } from '../constants/const';
import { format as dateFnsFormat, parseISO, parse } from 'date-fns'


export const transformValues = values => {
  const data = {...values};
  Object.keys(data).forEach(k => {
    if (['true', 'false'].includes(data[k])) {
      data[k] =!!data[k] ;
    }

    if ([
      'croptype',
      'farmland',
      'soiltype',
    ].includes(k)) {
      data[k] =parseInt(data[k], 10) ;
    }
  })
  return data;
};


export const formatDate = (date, format = DEFAULT_DATE_FORMAT) => {
  if (!date) {
    return '-';
  }

  return dateFnsFormat(parseISO(date, 'yyyy-MM-dd'), 'dd/MM/yyyy');
}
