import React  from  'react';
import { useOutletContext } from "react-router-dom";
import {
  Bars3Icon,
} from '@heroicons/react/24/outline'

const ContentHeader = ({ title, cta = null }) => {
  const [menuOpen, setMenuOpen] = useOutletContext();

  return (
    <div className="content-header__wrapper">
      <div className="content-header container">
        <div className="menu-toggle d-inline-flex d-lg-none">
          <Bars3Icon
            className="me-2"
            onClick={() => setMenuOpen(!menuOpen)}
            height={30}
          />
        </div>
        <h1>{title}</h1>

        <div className="ms-auto">
          {cta}
        </div>
      </div>
    </div>
  );
}

export default ContentHeader;
