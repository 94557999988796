import {
  COUNTRIES_FETCHED,
  CROP_TYPES_FETCHED,
  CULTIVATION_METHODS_FETCHED,
  GENDERS_FETCHED,
  IRRIGATION_TYPES_FETCHED,
  LANGUAGES_FETCHED,
  METRIC_SYSTEMS_FETCHED,
  OPERATION_TYPES_FETCHED,
  PIXEL_MAP_TYPES_FETCHED,
  SOIL_TYPES_FETCHED,
  USER_CATEGORIES_FETCHED,
  WORKSPACES_FETCHED,
} from "../actionTypes/actionTypes.js";

const initialState = {
  cropTypes: [],
  cultivationMethods: [],
  irrigationTypes: [],
  languages: [],
  metricSystems: [],
  operationTypes: [],
  pixelMapTypes: [],
  soilTypes: [],
  userCategories: [],
  workSpaces: [],
  genders: [],
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case CROP_TYPES_FETCHED:
      return {
        ...state,
        cropTypes: action.payload,
      };

    case IRRIGATION_TYPES_FETCHED:
      return {
        ...state,
        irrigationTypes: action.payload || [],
      };

    case CULTIVATION_METHODS_FETCHED:
      return {
        ...state,
        cultivationMethods: action.payload || [],
      };

    case WORKSPACES_FETCHED:
      return {
        ...state,
        workSpaces: action.payload || [],
      };

    case METRIC_SYSTEMS_FETCHED:
      return {
        ...state,
        metricSystems: action.payload,
      };

    case PIXEL_MAP_TYPES_FETCHED:
      return {
        ...state,
        pixelMapTypes: action.payload,
      };

    case OPERATION_TYPES_FETCHED:
      return {
        ...state,
        operationTypes: action.payload,
      };

    case USER_CATEGORIES_FETCHED:
      return {
        ...state,
        userCategories: action.payload,
      };

    case LANGUAGES_FETCHED:
      return {
        ...state,
        languages: action.payload,
      };

    case GENDERS_FETCHED:
      return {
        ...state,
        genders: action.payload,
      };

    case SOIL_TYPES_FETCHED:
      return {
        ...state,
        soilTypes: action.payload,
      };

    case COUNTRIES_FETCHED:
      return {
        ...state,
        countries: action.payload,
      };

    default:
      return state;
  }
};

export default globalReducer;
