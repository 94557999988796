import React from  'react';
import { Form, Field } from 'react-final-form';
import { useSelector } from 'react-redux';

import TextInput from  './../finalFormFields/TextInput';
import FileInput from  './../finalFormFields/FileInput';
import SelectInput from  './../finalFormFields/SelectInput';

const ProfileSettingsForm = ({ avatar_value, onSubmit, initialValues }) => {
  const metricSystems = useSelector((state) => state?.globals?.metricSystems);
  const userCategories = useSelector((state) => state?.globals?.userCategories);
  const languages = useSelector((state) => state?.globals?.languages);
  const genders = useSelector((state) => state?.globals?.genders);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={
        ({
           submitError,
           handleSubmit,
           form,
           submitting,
           pristine,
           values
        }) => (
          <form onSubmit={handleSubmit}>
            <Field
              component={TextInput}
              label="First name"
              name="first_name"
              type="text"
            />

            <Field
              component={TextInput}
              label="Last name"
              name="last_name"
              type="text"
            />

            <Field
              component={FileInput}
              label="Profile image"
              name="avatar"
            />

            {avatar_value && (
              <div>
                <img alt="profile-image" className="mb-3" width="130" src={avatar_value} />
              </div>
            )}

            <Field
              component={TextInput}
              label="Phone"
              name="phone"
              type="text"
            />

            <Field
              className="form-select"
              component={SelectInput}
              label="Metric system"
              name="metricsystem"
              options={metricSystems.map(ms => ({
                name: ms.name,
                value: ms.metricsystem_id,
              }))}
            />

            <Field
              className="form-select"
              component={SelectInput}
              label="Gender"
              name="gender"
              options={genders.map(ms => ({
                name: ms.name,
                value: ms.gender_id,
              }))}
            />

            <div className="d-none">
              <Field
                component={SelectInput}
                label="User type"
                name="usercategory"
                options={userCategories.map(uc => ({
                  name: uc.name,
                  value: uc.usercategory_id,
                }))}
              />
            </div>

            <Field
              className="form-select"
              component={SelectInput}
              label="Language"

              name="language"
              options={languages.map(lg => ({
                name: lg.name,
                value: lg.language_id,
              }))}
            />

            {submitError && <div className="error">{submitError}</div>}

            <div className="mt-4 d-flex">
              <button
                className="btn btn-lg btn-primary ms-auto"
                type="submit"
                disabled={submitting || pristine}
              >
                Save changes
              </button>
            </div>
          </form>
        )}
    />
  );
};

export default ProfileSettingsForm;
