import React, { useEffect, useState } from  'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import qs from 'query-string';
import { formatDate } from '../utils/utils';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ContentHeader from './../layouts/components/ContentHeader';
import { fetchOperationsDS } from '../dataServices/operations';
import GrowingSeasonListItem from './GrowingSeasonListItem';
import SearchForm from '../components/forms/SearchForm';
import Pagination from './../components/Pagination';

const OperationsView = ({ onMenuToggle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  let [searchParams, setSearchParams] = useSearchParams();
  const operations = useSelector((state) => state?.app?.operations);
  const pagination = useSelector((state) => state?.app?.pagination);
  const { search } = useLocation();
  const currentPage = qs.parse(search).page || 1;
  const q = qs.parse(search)?.q;
  const searchAsObject = Object.fromEntries(
    new URLSearchParams(searchParams)
  );

  useEffect(() => {
    dispatch(fetchOperationsDS(currentPage, undefined, { q })).then(() => {
      setIsLoading(false);
      document.querySelector('.page').scrollTo({top: 0, behavior: 'smooth'});
    });
  }, [search]);

  return (
    <>
      <ContentHeader
        title={t('Operations')}
        // cta={(
        //   <Link
        //     className="btn btn-primary ms-auto"
        //     to="/operations/add"
        //   >
        //     {t('Add New')}
        //   </Link>
        // )}
      />

      <section className="page">
        <div className="container">

          {isLoading && 'Loading...'}

          {!isLoading && (
            <div className="row">
              <div className="col-12 ">
                {!isLoading && operations && operations.length === 0 && (
                  <div className="text-center p-5">
                    <i>{t('no items found')}</i>
                  </div>
                )}

                {operations?.length > 0 && (
                  <table className="table table--clickable">
                    <thead>
                      <tr>
                        <th scope="col">{t('Date')}</th>
                        <th scope="col">{t('Operation')}</th>
                        <th scope="col">{t('Growing season')}</th>
                        <th scope="col">{t('Consumption')}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {operations?.length > 0 && operations.map(op => (
                        <tr key={op.operation_id} onClick={() => navigate(`/operations/${op.operation_id}/edit`)}>
                          <td style={{ width: '120px' }} className="discreet">
                            {formatDate(op.operationdate)}
                          </td>
                          <td>{op.title}</td>
                          <td>{op?.season_name}</td>
                          <td>{op?.consumption} lt</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>

              <div className="my-4">
                <div className="w-100">
                  {pagination && (
                    <Pagination
                      {...pagination}
                      changePage={newPage => {
                        setSearchParams({
                          ...searchAsObject,
                          page: newPage,
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  )
};

export default OperationsView;
