import React from  'react';
import { useTranslation } from 'react-i18next';

const SelectInput = ({
  label,
  type,
  size,
  extraLink,
  hideEmptyValue,
  wrapperClass = 'mb-3',
  input: {
    name,
    ...rest
  },
  options,
  input,
  meta: {
    touched,
    error,
    submitError,
  },
  disabled,
  meta,
}) => {
  const { t } = useTranslation();

  return (
    <div className={wrapperClass}>
      <label
        htmlFor={name}
        className="form-label d-flex"
      >
        {label}

        <div className="ms-auto d-inline-flex">
          {extraLink}
        </div>
      </label>

      <select
        name={name}
        disabled={disabled}
        className={`form-select form-control ${size ? `form-control-${size}` : '' }`}
        {...rest}
      >
        {!hideEmptyValue && (
          <option value="">{t('Select an option...')}</option>
        )}
        {options && options.map(opt => (
          <option
            disabled={opt.disabled}
            key={opt.value}
            value={opt.value}
          >
            {opt.name}
          </option>
        ))}
      </select>

      {(error || submitError) && touched && (
        <div className="text-danger my-1">{error || submitError}</div>
      )}
    </div>
  );
};

export default SelectInput;
