import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    // fallbackLng: 'el',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      // EN - English
      '1': {
        translation: {
          Dashboard: 'Dashboard',
          Farmlands: 'Fields',
          'My Growing Seasons': 'Cultivations',
          'AI Analytics': 'AI Analytics',
          'Transactions': 'Transactions',
          'Operations': 'Operations',
          'Connections': 'Connections',
          'Settings': 'Settings',
          'Logout': 'Logout',
          'items_found_single': 'Showing {{count}} result',
          'items_found_plural': 'Showing {{count}} results',
          'New Growing Season': 'New Cultivation',
          'Farmland': 'Farmland',
          'Select an option...': 'Select an option...',
          'Irrigation': 'Irrigation',
          'Name': 'Name',
          'Comments': 'Comments',
          'Crop': 'Crop',
          'no farmlands message': `You haven't added any fields yet.`,
          'no items found': `You haven't added any items yet.`,
          'Click here to add one now': 'Click here to add one now',
          'Welcome back': 'Welcome back',
          'Soil type': 'Soil Type',
          'Cancel': 'Cancel',
          'Submit': 'Submit',
          'Save Changes': 'Save Changes',
          'Lading...': 'Lading...',
          'Full name': 'Full name',
          'Country': 'Country',
          'Search': 'Search',
          'Delete': 'Delete',
          'Close': 'Close',
          'Edit': 'Edit',
          'Delete Growing Season': 'Delete Cultivation',
          'Are you sure you want to delete cultivation': 'Are you sure you want to delete',
          'no growing season found': `You haven't added any cultivation yet.`,
          'Stats by Crop Type': 'Stats by Crop',
          'Cultivation Method': 'Cultivation Method',
          'Name / Area': 'Name / Area',
          'Return to growing seasons': 'Cultivations',
          'Field': 'Field',
          'Choose farmer': 'Choose farmer',
          'Litre': 'Litre',
          'day_abr': 'day',
          'days_abr': 'days',
        },
      },
      // EL  - Greek
      '2': {
        translation: {
          Dashboard: 'Αρχική',
          Farmlands: 'Αγροτεμάχια',
          'My Growing Seasons': 'Καλλιέργειες',
          'AI Analytics': 'AI Analytics',
          'Transactions': 'Συναλλαγές',
          'Operations': 'Εργασίες',
          'Operation': 'Εργασία',
          'Connections': 'Connections',
          'Settings': 'Ρυθμίσεις',
          'Logout': 'Αποσύνδεση',
          'items_found_single': 'Βρέθηκε {{count}} αποτελέσμα',
          'items_found_plural': 'Βρέθηκαν {{count}} αποτελέσματα',
          'New Growing Season': 'Νέα καλλιέργεια',
          'Farmland': 'Αγροτεμάχιο',
          'Select an option...': 'Επιλέξτε...',
          'Irrigation': 'Άρδευση',
          'Name': 'Όνομα',
          'Comments': 'Σχόλια',
          'Crop': 'Καλλιέργεια',
          'no farmlands message': 'Δεν έχετε καταχωρήσει αγροτεμάχια',
          'Click here to add one now': 'Προσθέστε ένα τώρα',
          'Welcome back': 'Γεια σου',
          'Soil type': 'Τύπος εδάφους',
          'Cancel': 'Ακύρωση',
          'Back': 'Πίσω',
          'Submit': 'Καταχώρηση',
          'Save Changes': 'Αποθήκευση αλλαγών',
          'Save': 'Αποθήκευση',
          'Lading...': 'Φορτώνει...',
          'Full name': 'Όνομα',
          'Country': 'Χώρα',
          'Search': 'Αναζήτηση',
          'Delete': 'Διαγραφή',
          'Close': 'Κλείσιμο',
          'Edit': 'Επεξεργασία',
          'Delete Growing Season': 'Διαγραφή καλλιέργειας',
          'Are you sure you want to delete growing season': 'Είστε βέβαιος πως θελετε να γίνει διαγραφή της καλλιέργειας',
          'no growing season found': `Δεν βρέθηκε κάποια ενεργή καλλιέργεια.`,
          'no items found': 'Δεν βρέθηκαν εγγραφές',
          'Stats by Crop Type': 'Στατιστικα ανά καλλιέργεια',
          'Total Area (hr)': 'Συνολικό Εμβαδόν (hr)',
          'Total Expenses': 'Συνολικά έξοδα',
          'Total Income': 'Συνολικά έσοδα',
          'Balance': 'Ισοζύγιο',
          'Latest operations': 'Τελευταίες εργασίες',
          'Latest transactions': 'Τελευταίες κινήσεις',
          'View all': 'Εμφάνιση όλων',
          'Date': 'Ημερομηνία',
          'Add New': 'Νέα εγγραφή',
          'Manage farmlands': 'Διαχείριση αγροτεμαχίων',
          'Cultivation Method': 'Μέθοδος καλλιέργειας',
          'View': 'Εμφάνιση',
          'Farmland details': 'Πληροφορίες αγροτεμαχίου',
          'Growing season': 'Καλλιέργεια',
          'Return to growing seasons': 'Πίσω στις καλλιέργειας',
          'Weather': 'Καιρός',
          'Averages': 'Μέσοι όροι',
          'Select an index': 'Δείκτης',
          'Year': 'Έτος',
          'Area': 'Έκταση',
          'Perimeter': 'Περίμετρος',
          'Snapshot': 'Στιγμιότυπο',
          'Add new entry': 'Νέα εγγραφή',
          'Add Farmland': 'Νέο αγροτεμάχιο',
          'Edit Growing Season': 'Στοιχεία καλλιέργειας',
          'New selection': 'Νέα επιλογή',
          'Clear drawing': 'Καθαρισμός',
          'Name / Area': 'Όνομα / Τοποθεσία',
          'Field': 'Αγροτεμάχιο',
          'Description': 'Περιγραφή',
          'Add Operation': 'Καταχώρηση εργασίας',
          'Details': 'Πληροφορίες',
          'Title': 'Τίτλος',
          'Remove': 'Αφαίρεση',
          'Supervisors': 'Supervisors',
          'Available supervisors': 'Διαθέσιμοι supervisors',
          'Set as supervisor': 'Επιλογή ως supervisor',
          'Operation details': 'Λεπτομέρειες εργασίας',
          'Create operation': 'Νέα εργασία',
          'Update operation': 'Επεξεργασία εργασίας',
          'Operations calendar': 'Ημερολόγιο εργασιών',
          'Amount': 'Ποσό',
          'Weather forecast': 'Πρόγνωση καιρού',
          'Precipitation forecast': 'Πρόγνωση βροχοπτώσεων',
          'Total fuel Consumption': 'Συνολικη κατανάλωση καυσίμου',
          'Consumption': 'Κατανάλωση',
          'Attachments': 'Αρχεία',
          'Choose farmer': 'Επέλεξε αγρότη',
          'Temporal Evolution of Vegetation Indices': 'Χρονική εξέλιξη δεικτών βλάστησης',
          'Soil moisture sensor': 'Αισθητήρας υγρασίας εδάφους',
          'Litre': 'ΛΙΤΡΑ',
          'Cultivations': 'Καλλιέργειες',
          'Total Irrigation': 'Συνολικη κατανάλωση νερού',
          'Total Area': 'Συνολικη έκταση',
          'Nr. of fields': 'Αγροτεμάχια',
          'Show Timeline': 'Εμφάνιση Timeline',
          'Calculate total consumption': 'Αυτόματος υπολογισμός',
          'Sensor data': 'Δεδομένα αισθητήρα',
          'Loading...': 'Φορτώνει...',
          'Last operation': 'Τελευταία εργασία',
          'DFS': 'ΗΑΣ',
          'day_abr': 'ημέρα',
          'days_abr': 'ημέρες',
          'Files': 'Αρχεία',
          'from sowing': 'από τη σπορά',
          'Crop period': 'καλλιεργητική σεζόν',
          'Slope': 'Κλίση',
          'Elevation': 'Υψόμετρο',
          'Analysis date': 'Ημερομηνία ανάλυσης',
          'Soil Analysis': 'Εδαφολογικά Στοιχεία',
          'Add soil analysis data': 'Καταχώρηση εδαφολογικών στοιχείων',
        }
      },
    }
  });

export default i18n;
