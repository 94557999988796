import React, { useCallback } from  'react';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SelectInput from  './../finalFormFields/SelectInput';
import TextInput from  './../finalFormFields/TextInput';

const PixelMapFiltersForm = ({ onYearChange, onSubmit, initialValues, setPMFilters, farmlandId }) => {
//   const dispatch = useDispatch();
  const availableSnapshots = useSelector((state) => state?.app?.pixelMapSnapshots);
  const pixelMapTypes = useSelector((state) => state?.globals?.pixelMapTypes);
  const { t } = useTranslation();

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={
        ({
           submitError,
           handleSubmit,
           form,
           submitting,
           pristine,
           values
         }) => {
          return (
            <div className="indices-form-container">
              <form
                onSubmit={handleSubmit}
              >
                <OnChange name="pixelMapType">
                  {(value) => {
                    // form.change('year', null);
                    // setPMFilters({});
                    // form.change('snapshotDateTime', null);
                    onSubmit(values);
                  }}
                </OnChange>

                <OnChange name="year">
                  {(value) => {
                    onYearChange(value);
                  }}
                </OnChange>

                <OnChange name="snapshotDateTime">
                  {() => {
                    onSubmit(values);
                  }}
                </OnChange>

                <Field
                  // initialValue={pixelMapTypes[0].pmtype_id}
                  className="form-select"
                  size="sm"
                  component={SelectInput}
                  label={t('Select an index')}
                  name="pixelMapType"
                  options={pixelMapTypes && pixelMapTypes.map(pmt => ({
                    name: pmt.name,
                    value: pmt.pmtype_id,
                  }))}
                />

                <Field
                  component={TextInput}
                  size="sm"
                  label={t('Year')}
                  name="year"
                  min={2015}
                  max={2024}
                  type="number"
                />

                <Field
                  component={SelectInput}
                  wrapperClass=""
                  label={t('Snapshot')}
                  size="sm"
                  name="snapshotDateTime"
                  options={availableSnapshots && availableSnapshots.map(pmSnapshot => ({
                    // value: pmSnapshot?.id,
                    value: pmSnapshot?.properties?.datetime,
                    // disabled: pmSnapshot?.properties?.['eo:cloud_cover'] > 10,
                    name: (
                      <>
                        {format(new Date(pmSnapshot?.properties?.datetime), 'PPP')}
                        {' '}
                        {pmSnapshot?.properties?.['eo:cloud_cover'] > 70 && ' - Too Cloudy'}
                      </>
                    ),
                  }))}
                />

                {submitError && <div className="error">{submitError}</div>}

                {/*<div className="mt-4 d-flex">*/}
                {/*  <button*/}
                {/*    className="btn btn-primary ms-auto"*/}
                {/*    type="submit"*/}
                {/*    disabled={submitting || pristine}*/}
                {/*  >*/}
                {/*    Apply*/}
                {/*  </button>*/}
                {/*</div>*/}
              </form>
            </div>
          );
        }}
    />
  );
};

export default PixelMapFiltersForm;
