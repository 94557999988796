import toastr from 'toastr';
import { stringify, parse } from 'wkt';

import axiosClient from '../utils/axios';
import { DEFAULT_PAGE_SIZE } from '../constants/const';
import {
  FARMLANDS_FETCHED,
  FARMLAND_FETCHED,
  FARMLAND_SEASONS_FETCHED,
  PIXEL_MAP_DATA_FETCHED,
  PIXEL_MAP_DATA_RESET,
  PIXEL_MAP_SNAPSHOTS_FETCHED,
} from "../actionTypes/actionTypes";

export const fetchFarmLandsDS = (
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  filters = {},
) => async (dispatch) => {
  let filtersQS = '';
  if (filters?.q) {
    filtersQS += `&contains=${filters.q}`;
  }

  await axiosClient
    .get(`/fl/?page=${page}&page_size=${pageSize}${filtersQS}`)
    .then(({ data }) => {
      dispatch({
        type: FARMLANDS_FETCHED,
        payload: data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};

export const fetchFarmLandGrowingSeasonsDS = (farmlandId) => async (dispatch) => {
  await axiosClient
    .get(`/fl/${farmlandId}/se/?limit=1000`)
    .then(({ data }) => {
      dispatch({
        type: FARMLAND_SEASONS_FETCHED,
        payload: {
          farmlandId,
          growingSeasons: data.results,
        },
      });
    })
    .catch(function (err) {
      return 'error';
    });
};
export const uploadKMLFileDS = (file) => async (dispatch) => {
  const formData = new FormData();
  formData.append("file", file);
  return axiosClient.post('kmlread/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then(({ data }) => {
      return data?.coordinates.map(c => [c[1], c[0]]);
    })
    .catch(function (err) {
      return 'error';
    });;

  // await axiosClient
  //   .post(`/kmlread/`, fd)
  //   .then(({ data }) => {
  //     console.log({ data });
  //   })
  //   .catch(function (err) {
  //     return 'error';
  //   });
};
export const fetchFarmLandDS = (id) => async (dispatch) => {
  await axiosClient
    .get(`/fl/${id}/`)
    .then(({ data }) => {
      const newData = data;
      newData.coordinates.coordinates[0] = data.coordinates.coordinates[0].map(c => { return [c[1], c[0]]});;
      dispatch({
        type: FARMLAND_FETCHED,
        payload:  newData,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};

export const resetPixelMapData = () => async (dispatch) => {
  dispatch({
    type: PIXEL_MAP_DATA_RESET,
  });
};

export const createFarmLandDS = (values) =>  () => new Promise(async (resolve, reject) =>  {
  const _values = { ...values };

  axiosClient
    .post(`/fl/`, _values)
    .then(() => {
      toastr.success('Farmland created successfully!');
      resolve({ success: true });
    })
    .catch(function (err) {
      if (err?.response?.data) {
        resolve (err.response.data);
      }
    });
});

export const updateFarmLandDS = (values) =>  () => new Promise(async (resolve, reject) =>  {
  const _values = { ...values };
  if (typeof values.coordinates === 'object' && values.coordinates !== null) {
    _values.coordinates = stringify({
      type: 'Polygon', coordinates: [values.coordinates.coordinates[0].map(c => [c[1], c[0]])],
    });
  }

  axiosClient
    .put(`/fl/${values.farmland_id}/`, _values)
    .then(() => {
      toastr.success('Farmland updated successfully!');
      resolve({ success: true });
    })
    .catch(function (err) {
      if (err?.response?.data) {
        resolve (err.response.data);
      }
    });
});

export const deleteFarmLandDS = (farmLandId) =>  () => new Promise(async (resolve) =>
  axiosClient
    .delete(`/fl/${farmLandId}/`)
    .then(() => {
      toastr.success('Farmland deleted successfully!');
      resolve({ success: true });
    })
    .catch(function (err) {
      if (err?.response?.data) {
        resolve (err.response.data);
      }
    }));

export const fetchPMFilterDates = (id, year = 2023) => async (dispatch) => {
  await axiosClient
    .get(`/fl/${id}/pm/pmfilters/?fromdate=${year}-01-01&todate=${year}-12-31`)
    .then(({ data }) => {
      dispatch({
        type: PIXEL_MAP_SNAPSHOTS_FETCHED,
        payload: data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};

export const fetchPixelMapData = ({ farmLandId, fromDate, toDate, pmType }) => async (dispatch) => {
  await axiosClient
    .get(`/fl/${farmLandId}/pm/getpm/?fromdate=${fromDate}&todate=${toDate}&pmtype=${pmType}`)
    .then(({ data }) => {
      dispatch({
        type: PIXEL_MAP_DATA_FETCHED,
        payload: data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};

