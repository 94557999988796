import React  from  'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import Field from './../finalFormFields/Field';
import TextInput from  './../finalFormFields/TextInput';

const SearchForm = ({ initialValues, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={
        ({
           submitError,
           handleSubmit,
           form,
           submitting,
           pristine,
           values
        }) => (
          <form className="w-100 search-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-lg-8 col-xl-5">
                <div className="row mb-4">
                  <div className="col">
                    <Field
                      component={TextInput}
                      label=""
                      placeholder={`${t('Search')}...`}
                      name="q"
                      type="text"
                      wrapperClass=""
                    />
                  </div>

                  <div className="col-auto">
                    <button
                      className="btn btn-outline-dark"
                      type="submit"
                      disabled={submitting}
                    >
                      {t('Search')}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {submitError && <div className="error">{submitError}</div>}
          </form>
        )}
    />
  );
};

export default SearchForm;
