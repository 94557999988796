import { omit }  from 'lodash';

import {
  FARMLANDS_FETCHED,
  FARMLAND_FETCHED,
  FARMLAND_SEASONS_FETCHED,
  PIXEL_MAP_DATA_FETCHED,
  PIXEL_MAP_DATA_RESET,
  PIXEL_MAP_SNAPSHOTS_FETCHED,
  GROWING_SEASONS_FETCHED,
  GROWING_SEASON_FETCHED,
  OPERATION_FETCHED,
  OPERATIONS_FETCHED,
  TRANSACTIONS_FETCHED,
  TRANSACTION_FETCHED,
} from "../actionTypes/actionTypes.js";

const initialState = {
  farmLand: null,
  farmLands: [],
  pixelMapSnapshots: [],
  growingSeason: null,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case FARMLANDS_FETCHED:
      return {
        ...state,
        farmLands: action.payload.results,
        pagination: omit(action.payload, ['results']),
      };
    case PIXEL_MAP_SNAPSHOTS_FETCHED:
      return {
        ...state,
        pixelMapSnapshots: action.payload,
      };
    case PIXEL_MAP_DATA_FETCHED:
      return {
        ...state,
        pixelMapData: action.payload,
      };
    case PIXEL_MAP_DATA_RESET:
      return {
        ...state,
        pixelMapData: null,
      };
    case FARMLAND_FETCHED:
      return {
        ...state,
        farmLand: {
          [action.payload.farmland_id]: action.payload,
        }
      };
    case FARMLAND_SEASONS_FETCHED:
      return {
        ...state,
        farmLandGrowingSeasons: {
          [action.payload.farmlandId]: action.payload.growingSeasons,
        }
      };

    // Growing seasons
    case GROWING_SEASONS_FETCHED:
      return {
        ...state,
        growingSeasons: action.payload.results,
        pagination: omit(action.payload, ['results']),
      };
    case GROWING_SEASON_FETCHED:
      return {
        ...state,
        growingSeason: {
          [action.payload.season_id]: action.payload,
        }
      };

    // operations
    case OPERATIONS_FETCHED:
      return {
        ...state,
        operations: action.payload.results,
        pagination: omit(action.payload, ['results']),
      };
    case OPERATION_FETCHED:
      return {
        ...state,
        operations: {
          [action.payload.operation_id]: action.payload,
        }
      };

    // Transactions
    case TRANSACTIONS_FETCHED:
      return {
        ...state,
        transactions: action.payload.results,
        pagination: omit(action.payload, ['results']),
      };
    case TRANSACTION_FETCHED:
      return {
        ...state,
        transactions: {
          [action.payload.transaction_id]: action.payload,
        }
      };

    default:
      return state;
  }
};

export default appReducer;
