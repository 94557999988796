import React  from  'react';
import { Form, Field } from 'react-final-form';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import TextInput from  './../finalFormFields/TextInput';
import SelectInput from  './../finalFormFields/SelectInput';

const SignupForm = ({ onSubmit }) => {
  const countries = useSelector((state) => state?.globals?.countries);

  return (
    <Form
      onSubmit={onSubmit}
      render={
        ({
           submitError,
           handleSubmit,
           form,
           submitting,
           pristine,
           values
        }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="email"
              label="Email"
              component={TextInput}
              type="email"
            />

            <Field
              name="password1"
              label="Password"
              component={TextInput}
              type="password"
            />

            <Field
              name="password2"
              label="Confirm password"
              component={TextInput}
              type="password"
            />

            <Field
              // initialValue={}
              className="form-select"
              component={SelectInput}
              label="Select a country"
              name="country"
              options={countries && countries.map(c => ({
                name: c.name,
                value: c.country_id,
              }))}
            />

            {submitError && <div className="error">{submitError}</div>}

            <div className="d-grid mt-4">
              <button
                className="btn btn-lg btn-primary"
                type="submit"
                disabled={submitting || pristine}
              >
                Sign up
              </button>
            </div>

            <div>
              <p className="mb-0 mt-3  text-center">
                {`Already have an account? `}
                <Link className="text-primary fw-bold" to="/login">
                  Log in
                </Link>
              </p>
            </div>
          </form>
        )}
    />
  );
};

export default SignupForm;
