import React, { useEffect, useState } from  'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

import { useTranslation } from 'react-i18next';

import axiosClient from './../utils/axios';
import ContentHeader from './../layouts/components/ContentHeader';

import AIAnalyticsSearchForm from '../components/forms/AIAnalyticsSearchForm';

const AIAnalyticsView = () => {
  const { search } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [dataPerOperation, setDataPerOperation] = useState(null);
  const [dataPerCropType, setDataPerCropType] = useState(null);
  const [dataConsumptionPerCultivation, setDataConsumptionPerCultivation] = useState(null);
  const [operationTypes, setOperationTypes] = useState(null);
  const [cropTypes, setCropTypes] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const [growingSeasons, setGrowingSeasons] = useState([]);

  const searchAsObject = Object.fromEntries(
    new URLSearchParams(searchParams)
  );

  const fetchGrowingSeasons = async () => {
    await axiosClient
      .get('/se/?page_size=10000')
      .then(({ data }) => {
        setGrowingSeasons(data.results);
      })
      .catch(function (err) {
        return 'error';
      });
  };

  const fetchData = async () => {
    setIsLoading(false);
    const searchParams = qs.parse(search)
    await axiosClient
      .get(`/operations/bi/operations_per_cultivation/?operationtype_id=${searchParams?.operationtype_id || ''}&croptype_id=${searchParams?.croptype_id || ''}&cultivation_id=${searchParams?.cultivation_id || ''}`)
      .then(({ data }) => {
        setData(data);
      })
      .catch(function (err) {
        return 'error';
      });

    await axiosClient
      .get(`/operations/bi/operations_per_croptype/?operationtype_id=${searchParams?.operationtype_id || ''}&croptype_id=${searchParams?.croptype_id || ''}&cultivation_id=${searchParams?.cultivation_id || ''}`)
      .then(({ data }) => {
        setDataPerCropType(data);
      })
      .catch(function (err) {
        return 'error';
      });

    await axiosClient
      .get(`/operations/bi/operations_per_operationtype/?operationtype_id=${searchParams?.operationtype_id || ''}&croptype_id=${searchParams?.croptype_id || ''}&cultivation_id=${searchParams?.cultivation_id || ''}`)
      .then(({ data }) => {
        setDataPerOperation(data);
      })
      .catch(function (err) {
        return 'error';
      });

    await axiosClient
      .get(`/operations/bi/consumption_per_cultivation/?operationtype_id=${searchParams?.operationtype_id || ''}&croptype_id=${searchParams?.croptype_id || ''}&cultivation_id=${searchParams?.cultivation_id || ''}`)
      .then(({ data }) => {
        setDataConsumptionPerCultivation(data);
      })
      .catch(function (err) {
        return 'error';
      });
  };

  const fetchOperationTypes = async () => {
    await axiosClient
      .get('/operations/operationtype/')
      .then(({ data }) => {
        setOperationTypes(data);
      })
      .catch(function (err) {
        return 'error';
      });
  };
  const fetchCropTypes = async () => {
    await axiosClient
      .get('/ct/')
      .then(({ data }) => {
        setCropTypes(data);
      })
      .catch(function (err) {
        return 'error';
      });
  };

  useEffect(() => {
    fetchGrowingSeasons();
    fetchOperationTypes();
    fetchCropTypes();
  }, []);

  useEffect(() => {
    const searchParams = qs.parse(search);
    // console.log(Object.keys(searchParams));
    // if (Object.keys(searchParams).length === 0) {
    //   return;
    // }
    fetchData();
  }, [search]);


  const mappedDataPerCultivation = {
    labels: data && data.map(d => d.cultivation_name),
    datasets: [{
      label: 'Number of Operations per Cultivation',
      data: data &&  data.map(d => d.num_of_operations),
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(201, 203, 207, 0.2)'
      ],
      borderColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)'
      ],
      borderWidth: 1
    }]
  };

  const mappedDataPerOperationType = {
    labels: dataPerOperation && dataPerOperation.map(d => d.operationtype_name),
    datasets: [{
      label: 'Number of Operations per Operation Type',
      data: dataPerOperation &&  dataPerOperation.map(d => d.num_of_operations),
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(201, 203, 207, 0.2)'
      ],
      borderColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)'
      ],
      borderWidth: 1
    }]
  };

  const mappedDataPerCropType = {
    labels: dataPerCropType && dataPerCropType.map(d => d.croptype_name),
    datasets: [{
      label: 'Number of Operations per Crop Type',
      data: dataPerCropType &&  dataPerCropType.map(d => d.num_of_operations),
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(201, 203, 207, 0.2)'
      ],
      borderColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)'
      ],
      borderWidth: 1
    }]
  };
  const mappedDataConsumptionPerCultivation = {
    labels: dataConsumptionPerCultivation && dataConsumptionPerCultivation.map(d => d.cultivation_name),
    datasets: [{
      label: 'Consumption Per Cultivation',
      data: dataConsumptionPerCultivation &&  dataConsumptionPerCultivation.map(d => d.total_consumption),
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(201, 203, 207, 0.2)'
      ],
      borderColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)'
      ],
      borderWidth: 1
    }]
  };

  return (
    <>
      <ContentHeader
        title={t('AI Analytics')}
      />

      <section className="page">
        <div className="container">
          { cropTypes && operationTypes && (
            <div className="col-12">
              <div className="mt-3 bg-white p-4 filters-fixed">
                <AIAnalyticsSearchForm
                  initialValues={{
                    // croptype_id: cropTypes?.[0]?.croptype_id,
                  }}
                  growingSeasons={growingSeasons}
                  operationTypes={operationTypes}
                  cropTypes={cropTypes}
                  onSubmit={(values => {
                    setSearchParams({
                      ...searchAsObject,
                      ...values,
                    });
                  })}
                />
              </div>

              <div className="mt-3 p-3 bg-white">
                <div className="row">
                  <div className="col-12 col-lg-6 p-4">
                    { data && (
                      <Bar
                        type="bar"
                        data={mappedDataPerCultivation}
                        options={{
                          scales: {
                            y: {
                              beginAtZero: true,
                                stepSize: 1
                              },
                            },
                          }}
                      />
                    )}
                  </div>

                  <div className="col-12 col-lg-6 p-4">
                    { dataPerOperation && (
                      <Bar
                        type="bar"
                        data={mappedDataPerOperationType}
                        options={{
                          scales: {
                            y: {
                              beginAtZero: true,
                                stepSize: 1
                              },
                            },
                          }}
                      />
                    )}
                  </div>

                  <div className="col-12 col-lg-6 p-4 mt-5">
                    { dataPerCropType && (
                      <Bar
                        type="bar"
                        data={mappedDataPerCropType}
                        options={{
                          scales: {
                            y: {
                              beginAtZero: true,
                                stepSize: 1
                              },
                            },
                          }}
                      />
                    )}
                  </div>

                  <div className="col-12 col-lg-6 p-4 mt-5">
                    { dataPerCropType && (
                      <Bar
                        type="bar"
                        data={mappedDataConsumptionPerCultivation}
                        options={{
                          scales: {
                            y: {
                              beginAtZero: true,
                                stepSize: 1
                              },
                            },
                          }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            )}
        </div>
      </section>
    </>
  )
};

export default AIAnalyticsView;
