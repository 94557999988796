import React from  'react';
import { RouterProvider } from "react-router-dom";

import router from './Routes';




function App() {
  return (
    <RouterProvider fallbackElement={() => 'Error'} router={router} />
  );
}

export default App;
