import React from  'react';
import { useDispatch } from 'react-redux';
import { createOperationDS } from '../dataServices/operations';
import { useNavigate, useSearchParams } from 'react-router-dom';
import toastr from 'toastr';
import { useTranslation } from 'react-i18next';

import ContentHeader from './../layouts/components/ContentHeader';
import OperationTransactionForm from './../components/forms/OperationTransactionForm';

const OperationCreateView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onSubmit = async (values) => {
    return createOperation(values);
  }

  const createOperation = (values) => {
    return dispatch(createOperationDS(values))
      .then(data => {
        if (data.success) {
          navigate(`/operations/`);
          return;
        } else {
          if (data?.detail) {
            toastr.error(data?.detail);
          }
        }
        return data;
      })
      .catch(err => {
        console.log('ERROR #', err);
      });
  }

  return (
    <>
      <ContentHeader title={t('Add Operation')} />

      <section className="page">
        <OperationTransactionForm
          initialValues={{
            type: 'operation'
          }}
          onSubmit={onSubmit}
        />
      </section>
    </>
  )
};

export default OperationCreateView;
