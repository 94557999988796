import React, { memo, useState, useEffect } from 'react'
import { GoogleMap, LoadScript, DrawingManager, Polygon } from '@react-google-maps/api';
import { Button } from 'react-bootstrap';
import { stringify, parse } from 'wkt';
import { useTranslation } from 'react-i18next';

import KMLFileUploader from  './../components/KMLFileUploader'

const containerStyle = {
  width: '100%',
  height: '700px',
  maxHeight: '70vh',
};

const defaultCenter = {
  lat: 38.481056,
  lng: 22.978490,
};

const DrawPolygonMap = ({ onCoordsUpdate, initialValue, KMLCoords, allowEdit = true }) => {
  const [polygon, setPolygon] = useState(null);
  const { t } = useTranslation();
  const [initialPolygonPaths, setInitialPolygonPaths] = useState(null);
  const [KMLPolygonPaths, setKMLPolygonPaths] = useState(null);
  const [drawingMode, setDrawingMode] = useState(null);
  const [center, setCenter] = useState(defaultCenter);
  const [map, setMap] = useState(null);
  const [editable, setEditable] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() => {
    if (initialValue && map) {
      const coords = initialValue?.coordinates?.[0].map(point => ({ lat: point[0], lng: point[1]}));
      const bound = new window.google.maps.LatLngBounds();

      for (let i = 0; i < coords.length; i++) {
        bound.extend( new window.google.maps.LatLng(coords[i]['lat'], coords[i]['lng']) );
      }

      setCenter({
        lat: bound.getCenter().lat(),
        lng: bound.getCenter().lng(),
      });
      setInitialPolygonPaths(coords);
      map.fitBounds(bound);
    }
  }, [initialValue, map]);

  useEffect(() => {
    if (polygon) {
      updateFormValue();

      polygon.getPaths().forEach(function(path, index){
        window.google.maps.event.addListener(path, 'insert_at', function(){
          updateFormValue();
          // New point
        });
        window.google.maps.event.addListener(path, 'remove_at', function(){
          updateFormValue();
          // Point was removed
        });
        window.google.maps.event.addListener(path, 'set_at', function(){
          updateFormValue();
          // Point was moved
        });
      });
    }
  }, [polygon]);

  useEffect(() => {
    if (!KMLCoords) {
      return;
    }

    const coords = parse(KMLCoords).coordinates[0].map(point => ({ lat: point[1], lng: point[0] }));
    const bound = new window.google.maps.LatLngBounds();

    for (let i = 0; i < coords.length; i++) {
      bound.extend( new window.google.maps.LatLng(coords[i]['lat'], coords[i]['lng']) );
    }

    setCenter({
      lat: bound.getCenter().lat(),
      lng: bound.getCenter().lng(),
    });
    setKMLPolygonPaths(coords);
    map.fitBounds(bound);
  }, [KMLCoords]);

  const updateFormValue = (val) => {
    if (val === '') {
      onCoordsUpdate({ coords: val, isKML: false });
      return;
    }

    const len = polygon.getPath().getLength();
    const coords = [];
    for (let i = 0; i < len; i++) {
      coords.push([
        polygon.getPath().getAt(i).lng().toPrecision(6),
        polygon.getPath().getAt(i).lat().toPrecision(6),
      ]);
    }
    coords.push(coords[0]);
    const stringified = stringify({
      type: 'Polygon', coordinates: [coords],
    });
    onCoordsUpdate({ coords: stringified, isKML: false });
  }

  return (
    <LoadScript
      libraries={['drawing']}
      googleMapsApiKey="AIzaSyBYCV6nakW4zHapbRNzEUiCEMoaW10KZJ4"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        onLoad={(map) => {
          setMap(map);
        }}
        zoom={15}
        options={{
          fullscreenControl: true,
          streetViewControl: false,
          mapTypeId: 'hybrid',
          mapTypeControl: false,
          scrollwheel: isDrawing,
          tilt:0,
        }}
      >
        { !KMLPolygonPaths && initialPolygonPaths && (
          <Polygon
            editable={false}
            draggable={false}
            paths={initialPolygonPaths}
            options={{
              fillColor: '#ffb100',
              fillOpacity: 0.5,
              strokeColor: '#000',
              strokeWeight: 4,
              strokeOpacity: 0.9,
            }}
          />
        )}

        { KMLPolygonPaths && (
          <Polygon
            editable={false}
            draggable={false}
            paths={KMLPolygonPaths}
            options={{
              fillColor: '#ffb100',
              fillOpacity: 0.5,
              strokeColor: '#000',
              strokeWeight: 4,
              strokeOpacity: 0.9,
            }}
          />
        )}

        {!KMLPolygonPaths && allowEdit && (
          <>
            <div
              className="map-controls-container"
            >
              <Button
                className=""
                variant="primary"
                disabled={polygon || isDrawing}
                onClick={(e) => {
                  e.preventDefault();
                  setInitialPolygonPaths(null);
                  setEditable(true);
                  setIsDrawing(true);
                  if (polygon) {
                    polygon.setMap(null);
                    setPolygon(null);
                  }
                  setDrawingMode('polygon');
                  updateFormValue('');
                }}
              >
                {t('New selection')}
              </Button>

              <Button
                variant="secondary"
                disabled={!polygon}
                onClick={(e) => {
                  e.preventDefault('');
                  setIsDrawing(false);
                  if (polygon) {
                    polygon.setMap(null);
                    setPolygon(null);
                  }
                  setDrawingMode(null);
                  updateFormValue('');
                }}
              >
                {t('Clear drawing')}
              </Button>
            </div>

            { /* Child components, such as markers, info windows, etc. */ }
            <DrawingManager
              drawingMode={drawingMode}
              onPolygonComplete={(newPolygon) => {
                setPolygon(newPolygon);
                // newPolygon.setMap(null);
                setDrawingMode(null);
                setIsDrawing(false);
              }}
              options={{
                drawingControl: false,
                circleOptions: {
                  visible: false,
                },
                polygonOptions: {
                  draggable: false,
                  editable,
                  // clickable: true,
                  fillColor: '#ffb100',
                  fillOpacity: 0.5,
                  strokeColor: '#000',
                  strokeWeight: 4,
                  strokeOpacity: 0.9,
                },
              }}
            />
          </>
        )}
      </GoogleMap>
    </LoadScript>
  )
}

export default memo(DrawPolygonMap)
