const DateSelectField = ({
    label,
    type,
    max,
    min,
    size,
    wrapperClass = 'mb-3',
    input: {
      name,
      ...rest
    },
    meta: {
      touched,
      error,
      submitError,
    },
    meta,
  }) => (
  <div className={wrapperClass}>
    <label
      htmlFor={name}
      className="form-label"
    >
      {label}
    </label>

    <input
      type={type}
      min={min}
      max={max}
      className={`form-control ${size ? `form-control-${size}` : '' }`}
      {...rest}
    />
    {(error || submitError) && touched && (
      <div className="text-danger my-1">{error || submitError}</div>
    )}
  </div>
);

export default DateSelectField;
