import React, { useEffect, useState, useCallback } from  'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import toastr from 'toastr';
import { useTranslation } from 'react-i18next';

import { formatDate } from '../utils/utils';
import { fetchOperationDS } from '../dataServices/operations';
import axiosClient from '../utils/axios';
import ContentHeader from './../layouts/components/ContentHeader';

const SupervisorView = () => {
  const { t } = useTranslation();
  const userData = useSelector((state) => state?.auth?.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { operationId } = useParams();
  const [supervisors, setSupervisors] = useState([]);
  const [workspace, setWorkspace] = useState(null);
  const operation = useSelector((state) => state?.app?.operations?.[operationId]);

  useEffect(() => {
    fetchSupervisors();
  }, [userData]);

  useEffect(() => {
    fetchWorkSpace();
  }, [userData]);

  const fetchSupervisors = async () => {
    // await axiosClient
    //   .get('/ws/roles/')
    //   .then(( { data: roles }) => {
    //     console.log(roles)
    //   })
    //   .catch(function (err) {
    //     return 'error';
    //   });

    await axiosClient
      .get('/auth/users/?usercategory=2')
      .then(({ data }) => {
        setSupervisors(data?.results);
      })
      .catch(function (err) {
        return 'error';
      });
  };

  const fetchWorkSpace = async () => {
    await axiosClient
      // .get('/ws/')
      .get(`/ws/${userData?.active_ws}/`)
      .then(({ data }) => {
        setWorkspace(data);
      })
      .catch(function (err) {
        return 'error';
      });
  };

  const setAsSupervisor = async (supervisorId) => {
    await axiosClient
      .post('/ws/accounts/', {
        workspace: userData.active_ws,
        user: supervisorId,
        // role: 9,
      })
      .then(() => {
        toastr.success('Supervisor was set successfully!');
        fetchWorkSpace();
      })
      .catch(function (err) {
        return 'error';
      });
  }

  const removeSupervisor = async (workspaceAccountId) => {
    await axiosClient
      .delete(`/ws/accounts/${workspaceAccountId}/`)
      .then(() => {
        toastr.success('Supervisor removed successfully!');
        fetchWorkSpace();
      })
      .catch(function (err) {
        return 'error';
      });
  }

  return (
    <>
      <ContentHeader
        title={(
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
              {t('Supervisors')}
            </div>
          </div>
        )}
      />

      <section className="page page--farmland-view">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2 mb-3">
              <h5>{t('Available supervisors')}</h5>
            </div>
          </div>

          {supervisors && supervisors.map(({
            avatar, first_name, last_name, email, phone, pk, workspaceAccount_id,
          }) => {
            const isSelected = workspace?.workspace_accounts.some(s => s.user === pk);
            const workspaceAccountId = workspace?.workspace_accounts.find(s => s.user === pk)?.workspaceAccount_id;

            return (
              <div className="row" key={pk}>
                <div className="col-12 col-lg-8 offset-lg-2">
                  <div className={`card mb-3 ${isSelected ? 'border-primary' : ''}`}>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-2">
                          { avatar && (
                            <img width="100%" src={avatar} />
                          )}
                        </div>

                        <div className="col-10">
                          <h4 className="mt-2 mb-">{first_name} {last_name}</h4>
                          <div>
                            <span className="text-muted">{t('Email')}: </span>
                            {email || '-'}

                            <span className="text-muted ms-3">{t('Phone')}: </span>
                            {phone || '-'}
                          </div>

                          <div className="d-flex justify-content-end flex-row w-100">
                            { !isSelected && (
                              <button
                                onClick={() => setAsSupervisor(pk)}
                                className="btn btn-primary mt-4">
                                {t('Set as supervisor')}
                              </button>
                            )}

                            { isSelected && (
                              <button
                                onClick={() => removeSupervisor(workspaceAccountId)}
                                className="btn btn-danger mt-4">
                                {t('Remove')}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  )
};

export default SupervisorView;
