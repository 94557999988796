import React  from  'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import SelectInput from  './../finalFormFields/SelectInput';
import Field from './../finalFormFields/Field';
import TextInput from  './../finalFormFields/TextInput';

const SearchForm = ({ initialValues, onSubmit, growingSeasons, operationTypes, cropTypes }) => {
  const { t } = useTranslation();
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={
        ({
           submitError,
           handleSubmit,
           form,
           submitting,
           pristine,
           values
        }) => (
          <form className="w-100 search-form" onChange={handleSubmit}>
            <div className="row">
              <div className="col-12 col-lg-4">
                <Field
                  component={SelectInput}
                  label={t('Cultivation')}
                  name="cultivation_id"
                  options={growingSeasons && growingSeasons.map(gs => ({
                    name: gs.name,
                    value: gs.season_id,
                  }))}
                />
              </div>

              <div className="col-12 col-lg-4">
                <Field
                  component={SelectInput}
                  label={t('Operation type')}
                  name="operationtype_id"
                  options={operationTypes && operationTypes.map(ot => ({
                    name: ot.name,
                    value: ot.operationtype_id
                    ,
                  }))}
                />
              </div>

              <div className="col-12 col-lg-4">
                <Field
                  component={SelectInput}
                  label={t('Crop type')}
                  name="croptype_id"
                  options={cropTypes && cropTypes.map(ot => ({
                    name: ot.name,
                    value: ot.croptype_id,
                  }))}
                />
              </div>
              {/*<div className="col-12 col-lg-8 col-xl-5">*/}
              {/*  <Field*/}
              {/*    component={SelectInput}*/}
              {/*    label={t('Growing season')}*/}
              {/*    name="season"*/}
              {/*    disabled={!!initialValues.season}*/}
              {/*    options={growingSeasons && growingSeasons.map(gs => ({*/}
              {/*      name: gs.name,*/}
              {/*      value: gs.season_id,*/}
              {/*    }))}*/}
              {/*    required*/}
              {/*  />*/}
              {/*</div>*/}
            </div>

            {submitError && <div className="error">{submitError}</div>}
          </form>
        )}
    />
  );
};

export default SearchForm;
