import React  from  'react';
import toastr from 'toastr';
import { useNavigate } from 'react-router-dom';

import PasswordResetRequestForm from './../components/forms/PasswordResetRequestForm';
import axiosClient from '../utils/axios';
import { useDispatch } from 'react-redux';

const PasswordResetRequestView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = ({ email, password }) => new Promise(async resolve => {
  await axiosClient
    .post('/auth/password/reset/', {
      email,
      password,
    })
    .then(({ data }) => {
      toastr.success(data.detail);
      resolve();
    })
    .catch(function (err) {
      const { data } = err.response;
      const { non_field_errors } = data;
      if (non_field_errors) {
        toastr.error(non_field_errors, 'Error');
      }

      Object.keys(data).forEach(function(key, index) {
        data[key] = data[key][0] || data[key];
      });
      resolve(data);
    });
  });

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-12 col-md-7 col-lg-6 col-xl-5">
          <div className="border border-3 border-primary" />
          <div className="border-1 bg-white shadow-lg">
            <div className="rounded-bottom p-5">
              <h1 className="mb-5">Password Reset</h1>
              <PasswordResetRequestForm onSubmit={onSubmit} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default PasswordResetRequestView;
