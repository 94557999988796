import React, { useEffect }  from  'react';
import toastr from 'toastr';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { fetchCountries } from '../dataServices/global';
import SignUpForm from './../components/forms/SignupForm';
import axiosClient from '../utils/axios';

const SignUpView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCountries());
  }, []);

  const onSubmit = (values) => new Promise(async resolve => {
  const { email, password1, password2, country } = values;
  await axiosClient
    .post('/auth/registration/', {
      email,
      password1,
      password2,
      country,
    })
    .then(({ data }) => {
      toastr.success('Signed up successfully!');
      navigate('/login');
      resolve();
    })
    .catch(function (err) {
      const { data } = err.response;
      const { non_field_errors } = data;
      if (non_field_errors) {
        toastr.error(non_field_errors, 'Could not sign up', );
      }

      Object.keys(data).forEach(function(key, index) {
        data[key] = data[key][0] || data[key];
      });
      resolve(data);
    });
  });

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-12 col-md-7 col-lg-6 col-xl-5">
          <div className="border border-3 border-primary" />
          <div className="border-1 bg-white shadow-lg">
            <div className="rounded-bottom p-5">
              <h1 className="mb-5">Sign up</h1>
              <SignUpForm onSubmit={onSubmit} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default SignUpView;
