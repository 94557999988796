import React from  'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTransactionDS } from '../dataServices/operations';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import toastr from 'toastr';

import ContentHeader from './../layouts/components/ContentHeader';
import OperationTransactionForm from './../components/forms/OperationTransactionForm';

const TransactionCreateView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const farmlandId = searchParams.get('farmlandId');
  const growingSeasonId = searchParams.get('growingSeasonId');

  const onSubmit = async (values) => {
    return createOperation(values);
  }

  const createOperation = (values) => {
    return dispatch(createTransactionDS(values))
      .then(data => {
        if (data.success) {
          navigate(`/transactions/`);
          return;
        } else {
          if (data?.detail) {
            toastr.error(data?.detail);
          }
        }
        return data;
      })
      .catch(err => {
        console.log('ERROR #', err);
      });
  }

  return (
    <>
      <ContentHeader title="Add Transaction" />

      <section className="page">
        <OperationTransactionForm
          initialValues={{
            type: 'transaction',
            isexpense: true,
          }}
          onSubmit={onSubmit}
        />
      </section>
    </>
  )
};

export default TransactionCreateView;
