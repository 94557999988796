import React, { useCallback }  from  'react';
import { useDropzone } from "react-dropzone";
import { stringify } from 'wkt';

import { uploadKMLFileDS } from '../dataServices/farmLands';
import { useDispatch } from 'react-redux';

const KMLFileUploader = ({ onUploadComplete }) => {
  const dispatch = useDispatch();
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const binaryStr = reader.result;
        dispatch(uploadKMLFileDS(new Blob([binaryStr])))
          .then((data) => {
              const coords = data.map(pt => [pt[0], pt[1]]);
              onUploadComplete({
                coords: stringify({
                    type: 'Polygon',
                    coordinates: [coords],
                  }
                ),
                isKML: true,
              });
          });
      }
      reader.readAsArrayBuffer(file);
    })

  }, [])
  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return (
    <div className="drop-zone justify-content-center" {...getRootProps()}>
      <input {...getInputProps()} />
      <p className="mb-0">Drag 'n' Drop a KML file here, or click to select a file</p>
    </div>
  )
};

export default KMLFileUploader;
