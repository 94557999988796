import React, { useEffect, useCallback, useState }  from  'react';
import { useDropzone } from "react-dropzone";
import toastr from 'toastr';
import {
  XCircleIcon,
  DocumentIcon
} from '@heroicons/react/24/outline'

import axiosClient from '../../utils/axios';

const MultipleFileInput = (props) => {
  console.log({props});
  const [uploadedFiles, setUploadedFiles] = useState(props.input.value || []);
  const { isFarmLand, farmlandId, maxFiles = 3, isMultiple = true } = props;

  useEffect(() => {
    props.input.onChange(uploadedFiles);
  }, [uploadedFiles]);

  const FileInput = props => {
    const onDrop = useCallback(async acceptedFiles => {
      // Do something with the files
      const files = await Promise.all(acceptedFiles.map(async file => {
        if (!file?.name) {
          return;
        }

        const formData = new FormData();
        const arrayBuffer = await file.arrayBuffer();
        const blob = new Blob([new Uint8Array(arrayBuffer)], {type: file.type });
        formData.set('file', blob, file.name);
        formData.set('farmland_id', farmlandId);
        let url = '/operations/fileresources/';

        if (isFarmLand) {
          url = `/fl/fileresources/?farmland_id=${farmlandId}`;
        }
        return axiosClient
          .post(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data: attachment }) => {
            return attachment;
          })
          .catch(function (err) {
            console.log({ err });
            toastr.error('Could not upload file. File is not an image or file size too large.');
          });
      }));

      setUploadedFiles([...uploadedFiles, ...files.filter(f => f?.file)]);
      input.onChange([...uploadedFiles, ...files.filter(f => f?.file)].map(uf => ({
        fileresource_id: uf.fileresource_id,
      })));
      // const formData = new FormData();
      // // formData.append(key, values[key]);
      //
      // if (values?.avatar?.[0] && values.avatar[0].arrayBuffer) {
      //   const arrayBuffer = await values.avatar[0].arrayBuffer();
      //   const blob = new Blob([new Uint8Array(arrayBuffer)], {type: values.avatar[0].type });
      //   formData.set('avatar', blob, values.avatar[0].name);
      // }
      //
      // if (typeof values?.avatar === 'string' || values?.avatar === null) {
      //   formData.delete('avatar')
      // }
      //
      // axiosClient
      //   // .put(`/auth/user/`, formData, {
      //   .put(`/auth/loginuser/`, formData, {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   })
      //   .then(() => {
      //     toastr.success('Changes saved successfully!');
      //     window.location.reload();
      //     resolve();
      //   })
      //   .catch(function (err) {
      //     if (err?.response?.data) {
      //       resolve (err.response.data);
      //     }
      //   });
    }, []);
    console.log({ maxFiles, isMultiple });
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      maxFiles,
      multiple: isMultiple,
      // minSize: 0,
      // maxSize: 10240,
      accept: "image/jpeg, image/png"
    });

    return (
      <div {...getRootProps()}>
        {/*<input*/}
        {/*  {...getInputProps()}*/}
        {/*  {...props}*/}
        {/*/>*/}
        <input {...getInputProps()} {...props} />
        {isDragActive ? (
          <p className="drop-zone">Drop the files here ...</p>
        ) : (
          <p className="drop-zone">Drag 'n' Drop  your file here, or click to select file</p>
        )}
        {/*{props.input.value*/}
        {/*  ? props.input.value.map && props.input.value.map(file => {*/}
        {/*    return <div key={file.name}>{file.path}</div>;*/}
        {/*  })*/}
        {/*  : null}*/}
      </div>
    );
  };

  const {
    label,
    type,
    input: {
      name,
      ...rest
    },
    input,
    meta: {
      touched,
      error,
      submitError,
    },
    meta,
  } = props;

  const extractName = (f) => {
    const parts = f.file.split("/");
    return parts[parts.length - 1];
  }



  return (
    <div className="mb-3">
      <label
        htmlFor={name}
        className="form-label"
      >
        {label}
      </label>

      <FileInput {...props} />

      {uploadedFiles.length > 0 && (
        <div className="row my-6">
          {uploadedFiles.map((f, key) => (
            <div
              className="col-12 col-lg-6 attachment-item mb-4"
              key={key}
            >
              <span
                className="remove"
                onClick={() => {
                  setUploadedFiles(uploadedFiles.filter(oof => oof?.file && oof.file !== f.file));
                }}
              >
                <XCircleIcon
                  height={24}
                  fontWeight={600}
                  className="me-2"
                />
              </span>

              {f?.mimetype?.includes('image') && (
                <a href={f.file} target="_blank">
                  <img
                    style={{
                      width: '100%',
                      height: 'auto',
                    }}
                    src={f.file}
                  />
                </a>
              )}

              { !f?.mimetype?.includes('image') && (
                <a href={f?.file}  target="_blank" className="d-flex h-100 border-1 bg-light rounded p-4 flex-column align-items-center justify-content-center">
                  {f?.mimetype === 'application/pdf' && (
                    <DocumentIcon
                      className="mb-3"
                      width={65}
                    />
                  )}
                  {extractName(f)}
                </a>
              )}
            </div>
          ))}
        </div>
      )}
      {/*{uploadedFiles?.foreach((f) => (*/}
      {/*  <div>*/}
      {/*    {f.name}*/}
      {/*  </div>*/}
      {/*))}*/}

      {(error || submitError) && touched && (
        <div className="text-danger my-1">{error || submitError}</div>
      )}
    </div>
  );
};

export default MultipleFileInput;
