import { Form, Field } from 'react-final-form';
import { Link } from "react-router-dom";

const PasswordResetRequestForm = ({ onSubmit }) => {
  return (
    <Form
      onSubmit={onSubmit}
      render={
        ({
           submitError,
           handleSubmit,
           form,
           submitting,
           pristine,
           values
        }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="email"
              type="email"
            >
              {({ input, meta }) => (
                <div className="mb-3">
                  <label
                    className="form-label"
                  >
                    email
                  </label>
                  <input
                    {...input}
                    className="form-control"
                    placeholder="email"
                  />
                  {(meta.error || meta.submitError) && meta.touched && (
                    <div className="text-danger my-1">{meta.error || meta.submitError}</div>
                  )}
                </div>
              )}
            </Field>

            {submitError && <div className="error">{submitError}</div>}

            <div className="d-grid mt-4">
              <button
                className="btn btn-lg btn-primary"
                type="submit"
                disabled={submitting || pristine}
              >
                Reset Password
              </button>
            </div>
          </form>
        )}
    />
  );
};

export default PasswordResetRequestForm;
