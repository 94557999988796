import axiosClient from '../utils/axios';
import {
  USER_FETCHED,
} from "../actionTypes/actionTypes";

export const fetchCurrentUserDS = (id, onLoaded) => async (dispatch) => {
  await axiosClient
    //.get(`/auth/user/`)
    .get(`/auth/loginuser/`)
    .then(({ data }) => {
      dispatch({
        type: USER_FETCHED,
        payload: data,
      });
      // use user specified language
      onLoaded(data?.language.toString() || '2'); // 2 stands for greek
    })
    .catch(function (err) {
      return 'error';
    });
};

