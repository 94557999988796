import React, { useEffect, useState } from  'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import qs from 'query-string';
import { formatDate } from '../utils/utils';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';

import ContentHeader from './../layouts/components/ContentHeader';
import { fetchTransactionsDS } from '../dataServices/operations';
import GrowingSeasonListItem from './GrowingSeasonListItem';
import SearchForm from '../components/forms/SearchForm';
import Pagination from './../components/Pagination';

const TransactionsView = ({ onMenuToggle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  let [searchParams, setSearchParams] = useSearchParams();
  const transactions = useSelector((state) => state?.app?.transactions);
  const pagination = useSelector((state) => state?.app?.pagination);
  const { search } = useLocation();
  const currentPage = qs.parse(search).page || 1;
  const q = qs.parse(search)?.q;
  const searchAsObject = Object.fromEntries(
    new URLSearchParams(searchParams)
  );

  useEffect(() => {
    dispatch(fetchTransactionsDS(currentPage, undefined, { q })).then(() => {
      setIsLoading(false);
      document.querySelector('.page').scrollTo({top: 0, behavior: 'smooth'});
    });
  }, [search]);

  return (
    <>
      <ContentHeader
        title={t('Transactions')}
        cta={(
          <Link
            className="btn btn-primary ms-auto"
            to="/transactions/add"
          >

            {t('Add New')}
          </Link>
        )}
      />

      <section className="page">
        <div className="container">

          {isLoading && 'Loading...'}

          {!isLoading && (
            <div className="row">
              <div className="col-12 ">
                {!isLoading && transactions && transactions.length === 0 && (
                  <div className="text-center p-5">
                    <i>{t('no items found')}</i>
                  </div>
                )}

                {transactions?.length > 0 && (
                  <table className="table table--clickable">
                    <thead>
                      <tr>
                        <th style={{ width: '120px' }} scope="col">Date</th>
                        <th scope="col">Transaction</th>
                        <th style={{ width: '120px' }} scope="col">Amount</th>
                      </tr>
                    </thead>

                    <tbody>
                      {transactions?.length > 0 && transactions.map(op => (
                        <tr key={op.transaction_id} onClick={() => navigate(`/transactions/${op.transaction_id}`)}>
                          <td className="discreet">
                            {formatDate(op.transactiondate)}
                          </td>
                          <td>{op.title}</td>
                          <td>
                            <span className={op.isexpense ? 'text-danger' : 'text-success'}>{op.amount}&euro;</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>

              <div className="my-4">
                <div className="w-100">
                  {pagination && (
                    <Pagination
                      {...pagination}
                      changePage={newPage => {
                        setSearchParams({
                          ...searchAsObject,
                          page: newPage,
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  )
};

export default TransactionsView;
