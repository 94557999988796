import React from  'react';
import {
  useNavigate,
  createBrowserRouter,
  Navigate,
  Link,
} from "react-router-dom";

import RequireAuth from './components/RequireAuth';

// Views
import DashboardView from './views/DashboardView';
import SupervisorView from './views/SupervisorView';
import AIAnalyticsView from './views/AIAnalyticsView';

// Farmlands
import FarmLandCreateView from './views/FarmLandCreateView';
import FarmLandEditView from './views/FarmLandEditView';
import FarmLandsView from './views/FarmLandsView';
import FarmLandView from './views/FarmLandView';

// Growing Seasons
import GrowingSeasonCreateView from './views/GrowingSeasonCreateView';
import GrowingSeasonsView from './views/GrowingSeasonsView';
import GrowingSeasonView from './views/GrowingSeasonView';
import GrowingSeasonEditView from './views/GrowingSeasonEditView';

import OperationCreateView from './views/OperationCreateView';
import OperationEditView from './views/OperationEditView';
import OperationsView from './views/OperationsView';
import OperationView from './views/OperationView';

import TransactionCreateView from './views/TransactionCreateView';
// import OperationEditView from './views/OperationEditView';
import TransactionsView from './views/TransactionsView';
import TransactionView from './views/TransactionView';

import LoginView from './views/LoginView';
import PasswordResetRequestView from './views/PasswordResetRequestView';
import ProfileView from './views/ProfileView';
import SignUpView from './views/SignUpView';

// Layouts
import AuthLayout from './layouts/AuthLayout';
import DefaultLayout from './layouts/DefaultLayout';

const DummyComponent = () => {
  return (
    <div className="container">
      <h1 className="my-5">This page hasn't been implemented yet.</h1>
      <Link className="btn btn-primary" to="/growing-seasons">
        &laquo; Back to growing seasons
      </Link>
    </div>
  );
};

const Error404 = () => {
  return (
    <div className="container">
      <h1 className="my-5">Error 404</h1>
      <Link className="btn btn-primary" to="growing-seasons">
        &laquo; Back to growing seasons
      </Link>
    </div>
  );
};

const routerConfig = createBrowserRouter([
  {
    errorElement: <Error404 />,
    element: <AuthLayout />,
    children: [
      {
        path: "/login",
        element: <LoginView />,
      },
      {
        path: "/password-reset",
        element: <PasswordResetRequestView />,
      },
      {
        path: "/signup",
        element: <SignUpView />,
      },
    ],
  },

  {
    element: <RequireAuth />,
    children: [
      {
        element: <DefaultLayout />,
        children: [
          {
            path: '/profile-settings',
            element: <ProfileView />,
          },


          { path: '/',
            element: <DashboardView />,
          },

          { path: '/ai-analytics',
            element: <AIAnalyticsView />,
          },

          // Farm lands
          {
            path: '/farm-lands',
            element: <FarmLandsView />,
          },
          {
            path: '/farm-lands/add',
            element: <FarmLandCreateView />,
          },
          {
            path: '/farm-lands/:farmlandId',
            element: <FarmLandView />,
          },
          {
            path: '/farm-lands/:farmlandId/edit',
            element: <FarmLandEditView />,
          },

          // Operations
          {
            index: true,
            path: '/operations',
            element: <OperationsView />,
          },
          {
            path: '/operations/add',
            element: <OperationCreateView />,
          },
          {
            path: '/operations/:operationId',
            element: <OperationView />,
          },
          {
            path: '/operations/:operationId/edit',
            element: <OperationEditView />,
          },

          // Transactions
          {
            index: true,
            path: '/transactions',
            element: <TransactionsView />,
          },
          {
            path: '/transactions/add',
            element: <TransactionCreateView />,
          },
          {
            path: '/transactions/:transactionId',
            element: <TransactionView />,
          },
          {
            path: '/transactions/:transactionId/edit',
            element: <OperationEditView />,
          },

          // Supervisors
          {
            index: true,
            path: '/supervisors',
            element: <SupervisorView />,
          },

          // Growing seasons
          {
            index: true,
            path: '/growing-seasons',
            element: <GrowingSeasonsView />,
          },
          {
            path: '/growing-seasons/add',
            element: <GrowingSeasonCreateView />,
          },
          {
            path: '/growing-seasons/:growingSeasonId',
            element: <GrowingSeasonView />,
          },
          {
            path: '/growing-seasons/:growingSeasonId/edit',
            element: <GrowingSeasonEditView />,
          },
        ],
      },
    ],
  },
  {
    errorElement: <DummyComponent />,
  },
]);

export default routerConfig;
