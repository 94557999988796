import React, { useState } from 'react';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { useTranslation } from 'react-i18next';

const Pagination = ({ page_size, current_page, count, changePage }) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column-reverse flex-md-row justify-content-between align-items-center">
      <div className="mt-3 mt-md-0">
        <div style={{ width: 'fit-content' }}>
          <PaginationControl
            between={3}
            ellipsis={1}
            limit={page_size}
            total={count}
            page={current_page}
            // limit={20}
            // total={250}
            // page={page}
            changePage={changePage}
          />
        </div>
      </div>

      <div className="text-muted">
        {count === 1
          ? t('items_found_single', {count})
          : t('items_found_plural', {count})
        }
      </div>
    </div>
  );
}

export default Pagination;
