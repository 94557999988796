import React, { useState } from  'react';
import { Icon } from '@iconify/react';
import { NavLink } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/el';
import { Form, Field } from 'react-final-form';
import SelectInput from  './../components/finalFormFields/SelectInput';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  HomeIcon,
  ArrowRightOnRectangleIcon,
  RocketLaunchIcon,
  ViewfinderCircleIcon,
  Cog6ToothIcon,
  XMarkIcon,
  QueueListIcon,
} from '@heroicons/react/24/outline'
import { useDispatch } from 'react-redux';

import { updateProfileDS } from '../dataServices/profile';
import logo from './../img/ai-grow-logo.png';


const DefaultLayout = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const userLogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem('auth');
    navigate('/login');
  }
  const userData = useSelector((state) => state?.auth?.user);
  const workspaces = useSelector((state) => state?.globals?.workSpaces)
  const onWorkspaceSelect = ({ workspaceId }) => {
    return dispatch(updateProfileDS({
      ...userData,
      active_ws: workspaceId,
    }));
  };
  const languages = useSelector((state) => state?.globals?.languages);
  const userLangIso = languages && languages.find(l => l.language_id === userData.language)?.iso_code || 'en' ;
  moment.locale(userLangIso);
  const currentdate = moment().format('dddd, LL');

  return (
    <div className={`layout--default ${menuOpen ? 'menu-open' : ''}`}>
      <div className={`navbar-col ${menuOpen ? 'open' : ''}`}>
        <img className="logo" alt="logo" src={logo} />

        <div className="text-white mt-2 ps-3">
          {currentdate} <br />
          {`${userData?.first_name} ${userData?.last_name}`}
        </div>

        <div className="workspace-selector">
          {userData && (
            <Form
              onSubmit={onWorkspaceSelect}
              initialValues={{ workspaceId: userData?.active_ws }}
              render={
                ({
                   submitError,
                   handleSubmit,
                   form,
                   submitting,
                   pristine,
                   values
                 }) => (
                  <form onChange={handleSubmit}>
                    <div className="mt-4 px-3">
                      <Field
                        component={SelectInput}
                        name="workspaceId"
                        hideEmptyValue
                        label={(<span className="text-white">{t('Choose farmer')}</span>)}
                        options={workspaces?.map(ws => ({
                          name: (ws.creator?.first_name && ws.creator?.first_name !== '')
                            ? `${ws.creator?.first_name} ${ws.creator?.last_name}`
                            : ws.name,
                          value: ws.workspace_id,
                        }))}
                      />
                    </div>
                  </form>
                )}
            />
          )}
        </div>

        <div className="navbar-toggle">
          <XMarkIcon
            onClick={() => setMenuOpen(!menuOpen)}
            height={30}
          />
        </div>

        <nav className="main-nav">
          <ul className="nav-list">
            <li>
              <NavLink to="/" onClick={() => setMenuOpen(false)}>
                <div className="icon"><HomeIcon /></div>
                {t('Dashboard')}
              </NavLink>
            </li>

            <li>
              <NavLink to="/farm-lands" onClick={() => setMenuOpen(false)}>
                <div className="icon"><Icon icon="mdi:land-plots-circle-variant" /></div>
                {t('Farmlands')}
              </NavLink>
            </li>

            <li>
              <NavLink to="/growing-seasons" onClick={() => setMenuOpen(false)}>
                <div className="icon"><Icon icon="lucide:wheat" /></div>
                {t('My Growing Seasons')}
              </NavLink>
            </li>

            <li>
              <NavLink to="/operations" onClick={() => setMenuOpen(false)}>
                <div className="icon"><QueueListIcon /></div>
                {t('Operations')}
              </NavLink>
            </li>

            <li>
              <NavLink to="/ai-analytics"  onClick={() => setMenuOpen(false)}>
                <div className="icon"><RocketLaunchIcon /></div>
                {t('AI Analytics')}
              </NavLink>
            </li>

            {/*<li>*/}
            {/*  <NavLink to="/transactions" onClick={() => setMenuOpen(false)}>*/}
            {/*    <div className="icon"><CurrencyEuroIcon /></div>*/}
            {/*    {t('Transactions')}*/}
            {/*  </NavLink>*/}
            {/*</li>*/}

            {/*<li>*/}
            {/*  <Link to="/" className="disabled" onClick={() => setMenuOpen(false)}>*/}
            {/*    <div className="icon"><UserGroupIcon /></div>*/}
            {/*    {t('Connections')}*/}
            {/*  </Link>*/}
            {/*</li>*/}
          </ul>
        </nav>

        <div className="navbar__footer">
          <nav>
            <ul className="nav-list">
              <li>
                <NavLink to="/supervisors" onClick={() => setMenuOpen(false)}>
                  <div className="icon"><ViewfinderCircleIcon /></div>
                  {t('Supervisors')}
                </NavLink>
              </li>

              <li>

                <NavLink to="/profile-settings" onClick={() => setMenuOpen(false)}>
                  <div className="icon"><Cog6ToothIcon /></div>
                  {t('Settings')}
                </NavLink>
              </li>

              <li>
                <a href="#" onClick={userLogOut} >
                  <div className="icon"><ArrowRightOnRectangleIcon /></div>
                  {t('Logout')}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="content-col">
        <Outlet context={[menuOpen, setMenuOpen]} />
      </div>
    </div>
  );
};

export default DefaultLayout;
