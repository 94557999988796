import axios from 'axios';

export const apiBaseUrl = 'https://sap.telecom.tuc.gr';
const axiosInstance = axios.create({
  baseURL: `${apiBaseUrl}/api/v1`,
});
axiosInstance.interceptors.request.use(
  config => {
    const authData = localStorage.getItem('auth');
    if (authData) {
      const { access } = JSON.parse(authData);
      config.headers.Authorization = `Bearer ${access}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Add a 401 response interceptor
axiosInstance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (403 === error.response.status) {
    localStorage.removeItem('auth');
    window.location = '/login';
  } else {
    return Promise.reject(error);
  }
});

export default axiosInstance;
